import React, { memo, useState, useEffect } from "react";
import { Row, Col, Button, Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CountryPhoneInput from "../../components/CountryPhoneInput";
import { addRegionalManagers } from "../../actions/actions";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { useAuthContext } from "../../context/auth";
import { useApiContext } from "../../context/apiContext";
import * as yup from "yup";
import { toast } from "react-toastify";
import Toaster from "../../components/Toaster";

const AddRegionalManagerForm = memo(({ showModel, closeModel, updateList }) => {
  const apiRegion = useApiContext();
  const { authUser } = useAuthContext();
  const { t } = useTranslation();
  const [contact, setContact] = useState("");
  const [selectedContact, setSelectedContact] = useState("");
  const schema = yup.object().shape({
    name: yup.string().required(t("name_error")),
    email: yup.string().max(50).email(t('invalid_email_error')).required(t("email_error")),
    //region: yup.string(),
    contact: yup.lazy((value) => {
      if (
        value &&
        Object.values(value).some(
          (v) => !(v === null || v === undefined || v === "")
        )
      ) {
        return yup.string().min(10, t("contact_digit"));
      }
      return yup.mixed().notRequired();
    }),
  });

  const { register, handleSubmit, errors, setValue } = useForm({
    validationSchema: schema,
  });

  const postRegionManagers = (user) => {
    let changedContact = selectedContact.trim();
    user.contact = changedContact === "" ? changedContact : contact;
    // if (user.region === "no-region") {
    //   user.region = null;
    // }
    addRegionalManagers({
      ...user,
      createdBy: authUser.user.id,
      role: "RegionalManager",
    })
      .then((result) => {
        if (result.status === 200) {
          closeModel();
          updateList(result.data, "add");
          toast.dark(
            <Toaster
              className="text-capitalize"
              icon="notify"
              message={t("regional_manager_added_successfully")}
            />,
            { autoClose: 3000, hideProgressBar: true }
          );
        }
      })
      .catch((err) => {
        if (
          err.response &&
					((err.response.status === 400 && err.response.data && err.response.data.code === 'E_UNIQUE') ||
						(err.response.status === 500 && err.response.data && err.response.data.includes('E_UNIQUE')))
        ) {
          toast.dark(
            <Toaster icon="error" message={t("email_already_exists")} />
          );
        } else {
          toast.dark(
            <Toaster icon="error" message={t("something_went_wrong")} />
          );
        }
      });
  };

  return (
    <Modal
      show={showModel}
      onHide={closeModel}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Form onSubmit={handleSubmit(postRegionManagers)}>
        <Modal.Header className="bg-primary" closeButton>
          <Modal.Title className="lead text-capitalize text-white mb-0 font-weight-bold">
            {t("regional_manager")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <Row>
            <Col lg={6}>
              <Form.Group controlId="formBasicName">
                <Form.Label className="text-capitalize" size="lg">
                  {t("name")} <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  name="name"
                  size="lg"
                  type="text"
                  ref={register()}
                  isInvalid={!!errors.name}
                />
                {errors.name && (
                  <Form.Control.Feedback type="invalid">
                    {errors.name.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="text-capitalize" size="lg">
                  {t("email")} <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  name="email"
                  size="lg"
                  type="email"
                  ref={register()}
                  isInvalid={!!errors.email}
                />
                {errors.email && (
                  <Form.Control.Feedback type="invalid">
                    {errors.email.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group controlId="formBasicPhone">
                <Form.Label className="text-capitalize" size="lg">
                  {t("phone_number")}
                </Form.Label>

                <CountryPhoneInput
                  onChangeVal={(val, country, e, formattedValue) => {
                    let contactNumber =
                      formattedValue &&
                      formattedValue.slice(country.dialCode.length + 1);
                    setContact(formattedValue);
                    setSelectedContact(contactNumber);
                    let formContactVal =
                      val &&
                      val.match(/\d/g).join("").slice(country.dialCode.length);
                    setValue("contact", formContactVal, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                  }}
                  phoneInputValue={contact}
                />
                <Form.Control
                  name="contact"
                  size="lg"
                  type="text"
                  ref={register()}
                  isInvalid={!!errors.contact}
                  className="d-none"
                />
              </Form.Group>
              {errors.contact && (
                <div className="custom-invalid-feedback text-danger">
                  {errors.contact.message}
                </div>
              )}
            </Col>
            {/* <Col lg={6}>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label className="text-capitalize" size="lg">
                  {t("region")}
                </Form.Label>
                <Form.Control
                  as="select"
                  name="region"
                  size="lg"
                  ref={register()}
                >
                  <option value="no-region">No Region</option>
                  {apiRegion.regionApi.map((item) => (
                    <option value={item.id} key={item.id}>
                      {item.name}({item.regionId})
                    </option>
                  ))}
                </Form.Control>
                {errors.region && (
                  <Form.Control.Feedback type="invalid">
                    {errors.region.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col> */}
          </Row>
        </Modal.Body>
        <Modal.Footer className="pt-0">
          <Button
            variant="primary"
            type="submit"
            className="text-capitalize"
            size="lg"
          >
            {t("create")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
});

AddRegionalManagerForm.propTypes = {
  showModel: PropTypes.bool,
  closeModel: PropTypes.func.isRequired,
  updateList: PropTypes.any,
};

AddRegionalManagerForm.defaultProps = {
  showModel: false,
};
export default AddRegionalManagerForm;
