import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Navbar as BootstrapNavbar,
  Dropdown as BootstrapDropdown,
  Form as BootstrapForm,
  Container as BootstrapContainer,
} from "react-bootstrap";
import styled from "styled-components";
import { useParams, withRouter, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "../../assets/images/Mueller_logo.svg";
import BackArrow from "../../assets/images/white-back-arrow.svg";
import { useAuthContext } from "../../context/auth";
import GlobalSearch from "../GlobalSearch/GlobalSearch";
import "react-toastify/dist/ReactToastify.css";
import { getUser } from "../../actions/actions";

const Header = () => {
  const { t } = useTranslation();
  const { setAuthUser, authUser } = useAuthContext();
  const { dairyID } = useParams();
  const history = useHistory();
  const user = getUser();
  const logOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("lastUrl");
    localStorage.setItem("logoutMode", true);
    setAuthUser();
    window.location.href = "/login";
  };
  const role = () => {
    if (authUser.user.role === "SuperAdmin") {
      return t('super_admin');
    } else if (authUser.user.role === "RegionalManager") {
      return t('regional_manager');
    } else if (authUser.user.role === "SuperAdmin") {
      return t('super_admin');
    } else if (authUser.user.role === "DairyAdmin") {
      return t('site_admin');
    } else if (authUser.user.role === "DairyOperator") {
      return t('site_operator');
    } else if (authUser.user.role === "MilkHauler") {
      return t('milk_hauler');
    } else {
      return authUser.user.role;
    }
  };
  return (
    <BootstrapNavbar
      expand="lg"
      bg="primary"
      className={`${dairyID ? "pt-0 pb-2" : "pt-0 pb-2"}`}
      sticky="top"
    >
      <BootstrapContainer>
        <BootstrapNavbar.Brand
          className="back-arrow"
          onClick={history.goBack}
        // style={{
        //   paddingTop: "13px",
        //   marginRight: "11px",
        // }}
        >
          <img src={BackArrow} style={{ cursor: "pointer" }} />
        </BootstrapNavbar.Brand>
        <BootstrapNavbar.Brand
          onClick={() => history.push("/")}
          className="mr-auto mt-1"
        >
          <img
            src={logo}
            style={{ cursor: "pointer" }}
            className="d-inline-block align-bottom w-75"
            alt="Mueller Logo"
          />
        </BootstrapNavbar.Brand>
        <BootstrapNavbar.Toggle
          aria-controls="responsive-navbar-nav"
          className="mt-2 pr-0"
        />
        <BootstrapNavbar.Collapse id="responsive-navbar-nav">
          <BootstrapForm className="ml-auto mr-4 mt-2">
            {user.role === "SuperAdmin" || user.role === "RegionalManager" ? (
              <div style={{ width: "max-content" }}>
                <GlobalSearch />
              </div>
            ) : null}
          </BootstrapForm>

          <BootstrapDropdown className="mt-2">
            <BootstrapDropdown.Toggle
              variant="none"
              className="shadow-none header-dropdown border-0"
              id="dropdown-basic"
            >
              <div className="text-white header-menu">
                <div className="login-user-name text-truncate">
                  {authUser.user.name}
                </div>
                <div className="mb-0 user-role">{role()}</div>
              </div>
            </BootstrapDropdown.Toggle>

            <BootstrapDropdown.Menu
              className="py-0 rounded border-0"
              menualign="right"
              flip={false}
            >
              <BootstrapDropdown.Item
                onClick={() => {
                  history.push("/profile");
                }}
                className="delete-icon px-3 py-2"
              >
                {t("user_detail")}
              </BootstrapDropdown.Item>

              <BootstrapDropdown.Item
                onClick={() => {
                  history.push("/change-password");
                }}
                className="delete-icon text-capitalize px-3 py-2"
              >
                {t("change_password")}
              </BootstrapDropdown.Item>
              <BootstrapDropdown.Item
                onClick={logOut}
                href="/login"
                className="delete-button px-3 py-2 text-danger"
              >
                {t("log_out")}
              </BootstrapDropdown.Item>
            </BootstrapDropdown.Menu>
          </BootstrapDropdown>
        </BootstrapNavbar.Collapse>
      </BootstrapContainer>
    </BootstrapNavbar>
  );
};

Header.propTypes = {
  /** Input field search placeholder */
  placeholder: PropTypes.string,
  /** Search field name */
  searchName: PropTypes.string,
  /** Search field value */
  searchValue: PropTypes.string,
};
Header.defaultProps = {
  placeholder: "Search",
};
export default withRouter(Header);
