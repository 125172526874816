import React, { memo, useEffect, useState } from "react";
import { Row, Col, Tabs, Tab, Container } from "react-bootstrap";
import Dropdown from "../components/CustomDropdown";
import Breadcrumb from "../components/Breadcrumb";
import FarmUsers from "../containers/Tank/FarmUsers";
import Alerts from "../containers/Tank/Alerts";
import TankListing from "../containers/TankManagement/TankListing";
import TankListingMobile from "../containers/TankManagement/TankListingMobile";
import moment from "moment";

import { useTranslation } from "react-i18next";
import { getUser } from "../actions/actions";
import { useMediaQuery } from "react-responsive";

const TankPage = memo(() => {
  const { t } = useTranslation();
  const user = getUser();

  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1200 });

  useEffect(() => {
    document.body.classList.add("tank-page");
  }, []);

  const [dropdownTime, setDropdownTime] = useState();

  const handleClick = (time) => {
    setDropdownTime(time);
  };

  return (
    <>
      <Container>
        <Row className="mt-4 mb-3">
          <Col lg={7} md={5} sm={12} className="pl-0">
            <Breadcrumb />
          </Col>
          <Col
            lg={5}
            md={7}
            sm={12}
            className="d-lg-flex d-md-flex justify-content-end align-items-center"
          >
            <div className="d-lg-flex d-md-flex justify-content-end align-items-center">
              <span className="font-weight-bold mr-2">
                {dropdownTime === undefined
                  ? moment(new Date()).format("MMM DD, hh:mm A")
                  : moment
                      .tz(new Date(), dropdownTime)
                      .format("MMM DD, hh:mm A")}
              </span>
              <Dropdown time handleClick={(time) => handleClick(time)} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Tabs
          defaultActiveKey="tanks"
          className="main-tab text-capitalize mb-4 container full-width"
        >
          <Tab
            eventKey="tanks"
            title={t("devices")}
            className={isDesktopOrLaptop ? "tank-list" : "tank-list mobile"}
          >
            {isDesktopOrLaptop ? (
              <TankListing dropdownTime={dropdownTime} />
            ) : (
              <TankListingMobile />
            )}
          </Tab>
          {user.role === "Sanitarian" || user.role === "MilkHauler" ? (
            ""
          ) : (
            <Tab
              className="border-0 container full-width"
              eventKey="farm users"
              title={t("dashboard_users")}
            >
              <FarmUsers />
            </Tab>
          )}
          {user.role === "Sanitarian" ? (
            ""
          ) : (
            <Tab eventKey="alerts" title={t("alerts")} className="container full-width">
              <Alerts />
            </Tab>
          )}
        </Tabs>
      </Container>
    </>
  );
});

export default TankPage;
