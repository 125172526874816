import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import Graph from "../../components/Graph";
import GraphLoader from "../../components/GraphLoader";
import NoDataAvailable from "../../components/NoDataAvailable";
import { GRAPH_VIEW } from "actions/passwordRegex";
const CHILLER = memo(
  ({
    deviceType,
    graphLoader,
    chillerGraphData,
    washData,
    currentActiveView,
    sensors,
    showAnnotation,
    alerts,
    manualAnnotation,
    getAlerts,
    configId,
    dropdownTime,
    isResponsiveMode,
  }) => {
    const { t } = useTranslation();
    return (
      <>
        {deviceType === "CHILLER" && currentActiveView === GRAPH_VIEW ? (
          graphLoader ? (
            <GraphLoader />
          ) : chillerGraphData === "" ? (
            <center>
              <NoDataAvailable title={t("no_data")} />
            </center>
          ) : (
            <Row>
              <Col lg={12}>
                <Graph
                  data={chillerGraphData}
                  washData={washData}
                  sensors={sensors.CHILLER}
                  graphId="chiller"
                  showAnnotation={showAnnotation}
                  alerts={alerts}
                  manualAnnotation={manualAnnotation}
                  getAlerts={getAlerts}
                  configId={configId}
                  dropdownTime={dropdownTime}
                  isResponsiveMode={isResponsiveMode}
                  deviceType={deviceType}
                />
              </Col>
            </Row>
          )
        ) : null}
      </>
    );
  }
);

export default CHILLER;
