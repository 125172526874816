import React, { useState } from "react";
import { Row, Col, Tabs, Tab, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../components/Breadcrumb";
import DairyFarmTab from "../containers/Dairy/DairyFarmTab";
import TechniciansTab from "../containers/Dairy/TechniciansTab";
import CalibrationChartTab from "../containers/Dairy/CalibrationChartTab"
import { getUser } from "../actions/actions";

const DairiesPage = () => {
  const user = getUser();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('dairyFarms');

  return (
    <>
      <Container>
        <Row className="mt-4 mb-3">
          <Col lg={12} className="pl-0">
            <Breadcrumb />
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Tabs
              defaultActiveKey="dairyFarms"
              className="main-tab text-capitalize mb-4"
              onSelect={(eventKey) => setActiveTab(eventKey)}
            >
              <Tab eventKey="dairyFarms" title={t("sites")}>
                <DairyFarmTab />
              </Tab>
              {user.role !== "Technician" &&
                user.role !== "DairyAdmin" &&
                user.role !== "DairyOperator" &&
                user.role !== "Sanitarian" &&
                user.role !== "MilkHauler" ? (
                  <Tab
                    className="border-0"
                    eventKey="technicians"
                    title={t("technicians")}
                  >
                    <TechniciansTab activeTab={activeTab} />
                  </Tab>
                ) : (
                  ""
                )}
              <Tab eventKey="calibration_charts" title={t("calibration_charts")}>
                <CalibrationChartTab activeTab={activeTab} />
              </Tab>
            </Tabs>

          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DairiesPage;
