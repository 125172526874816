import React, { memo } from "react";
import { Container, Image, Row, Col } from "react-bootstrap";
import MuellerLogo from "../../assets/images/Mueller_dark_logo.svg";
import backarrow from "../../assets/images/black-md-arrow.svg";

const PrivacyPolicyPageJapanese = memo(() => {
    return(
        <Container>
            
            <div className="mb-5 mt-5  ">
                <Row>
                    <Col
                        xs={{ span: 1, offset: 0 }}
                        sm={{ span: 1, offset: 0 }}
                        md={{ span: 1, offset: 0 }}
                        lg={{ span: 1, offset: 0 }}
                        className="pl-3"
                    ><img src={backarrow} className="mt-2 p-3 text-center back-arrow-hover rounded" style={{cursor: 'pointer'}} onClick={() => {window.history.back()}} /></Col>
                <Col
                    xs={{ span: 8, offset: 1 }}
                    sm={{ span: 10, offset: 0 }}
                    md={{ span: 10, offset: 0 }}
                    lg={{ span: 10, offset: 0 }}
                    className="text-center mr-1">
                        {/* <img  src={MuellerLogo} className="logo-style-privacy-policy-page" /> */}
                </Col>
                </Row>
                <Row>
                <Col
                xs={{ span: 12, offset: 0 }}
                sm={{ span: 12, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
                >
                <div className="mt-5 col-12">
                    <h2>個人情報保護方針</h2>
                </div>

                <div>
                <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                    <h3 className="text-primary">このウェブサイトはPaulMuellerCompanyによって管理されています。</h3>
                        <p>Paul Mueller Company のウェブサイトおよびそこに掲載されている製品とサービスを 使用すると、当社にお客様の個人データが送信されます。また、本ウェブサイトは、お
使いのコンピュータに複数のブラウザクッキーを保存します。これらのクッキーは、お
使いのデバイスで本ウェブサイトが円滑に動作するため、また、より価値の高いユーザ
ー体験を提供するために使用されます。</p>
                        <p>以下に、Paul Mueller Company が収集するデータの種類、それらを収集する理由およ び収集したデータの利用方法について説明します。当社が使用するクッキーの種類と、 その理由についても説明します。このプライバシーステートメントは、当社ウェブサイ ト全体に関するものです。</p>
                    <h3 className="text-primary">クッキー</h3>
                        <p>本ウェブサイトが円滑かつ高速に機能し、お客様の設定に適合できるようにするため
に、お客様の本ウェブサイトの利用に関する特定の情報が収集されます。この情報は、
一般に「クッキー」と呼ばれるものを使用して収集されます。これらは、小さなテキス
トファイルで、ウェブサイトを閲覧することにより自動的にお使いのコンピュータに記
録されます。お使いのブラウザの設定により、「クッキー」の使用を無効にできます。
お使いのブラウザの「クッキー」管理機能により、ご自身の判断で「クッキー」を削除
できます。ただし、削除すると、本ウェブサイトでのユーザー体験は最適化されなくな
ります。また、サードパーティがクッキーをお客様のデバイスに保存し、同様の個人デ
ータを収集することがあります。</p>
                    <h3 className="text-primary">個人データの収集方法</h3>
                       <p>
Paul Mueller Company のウェブサイトを閲覧すると、以下の手段でユーザーの個人デ ータが当社に送信されます。</p>
                      <ul>
                            <li>ウェブサイトのナビゲーションを通じた自動的に収集されるデータ(例え ば、クッキー識別子、デバイスの種類、ブラウザの種類、および閲覧したサ イトのページ)。</li>
                            <li>お問い合わせフォームやウェブサイト上の他のインターネットフォームを使 用してお客様がメッセージを送信することによる、お客様が自発的に提供さ れた情報。</li>
                        </ul>
                    <h3 className="text-primary">個人データの使用目的</h3>
                        <p>Paul Mueller Company は、以下の目的のためにお客様のデータを保管し処理します。</p>
                        <ul>
                            <li>ウェブサイトの最適化を目的とした統計の収集及び分析</li>
                            <li>当社からお客様が購入した製品およびサービスの納品</li>
                            <li>お問い合わせ履歴の構築</li>
                            <li>メールマーケティングおよびニュースレターの送信</li>
                        </ul>
                    <h3 className="text-primary">個人データの保護方法</h3>
                        <p>当社は、お客様の個人データを保護するために、適用されるデータ保護およびデータセ
キュリティ法規制に一致する適切な措置を講じています。これには、当社サービスプロ
バーダーにお客様のデータの機密性とセキュリティを保護するように適切な措置を講じ
るよう要請することも含まれます。保護措置の実施にかかる費用および保護すべきデー
タの性質の最新の状況により、お客様の個人データの破壊、紛失、改ざん、不正な開
示、またはデータへのアクセスといったリスクを防止するため、当社は技術的および組
織的な対策を制定します。</p>
                    <h3 className="text-primary">Paul Mueller Company がお客様の個人データを共有する 相手</h3>
                        <p>Paul Mueller Company はお客様の個人データを機密情報として取扱います。当社は、
ご要望いただいた製品やサービスをお客様に提供するために必要な場合のみ、Paul
Mueller Company の信頼できるメーカー代理店および正規販売代理店網とお客様のデ
ータを共有します。</p>
                        <p>当社は、製品の集荷や、設備の点検、管理規制遵守の保証に必要な場合、Milk Secure
ユーザーからの個人データのうち限られた一部を牛乳輸送業者、各地の酪農設備販売業
者、および検査官と共有します。</p>
                        <p>当社は、お客様から明示的同意を得ることなく、他者にお客様の情報を販売することは
一切ありません。</p>
                    <h3 className="text-primary">お問い合わせ、メールマーケティングおよび/または広告</h3>
                        <p>メールマーケティングや不要な広告を受け取った場合、お客様は当社からの連絡を希望
しない旨を、いつでも意思表示できます。購読解除に費用は発生しません。</p>
                    <h3 className="text-primary">未成年者の個人データ</h3>
                       <p>お客様が未成年、すなわち 16 歳未満の場合、ご両親のどちらかまたは法的保護者の一 人の同意なしでは、個人データの処理に同意できません。16 歳未満の場合、ご両親ま たは法的保護者がこのステートメントをお読みになることが重要です。また、ご両親ま たは法的保護者は、お客様が提示した個人データに関してお客様が有する権利を行使で きます。例えば、個人データの(更なる)処理に反対する権利またはお客様のデータに アクセスし訂正する権利があります。</p>
                    <h3 className="text-primary">お客様の個人データに関しお客様ができること</h3>
                        <p>当社では、お客様がご自身の個人データにアクセスできるよう努めています。通常、お
客様は匿名でお客様のデータを管理(たとえばお客様のアカウントにログインして)お
よび更新、変更、または、法的に可能な場合、削除できます。この場合、お客様がご自
身の個人データを管理されることを強くお勧めいたします。</p>
                        <p>次のような場合は、いつでも当社にお問い合わせください。</p>
                        <ul>
                            <li>当社に提供されたデータの確認、変更または削除(何らかの理由で当該デー タの保持を許可または要求されていない範囲で) </li>
                            <li>特定のデータ処理操作に反対すること(たとえば、マーケティング・コミュ ニケーションからのオプトアウト) </li>
                            <li>お客様のデータのコピーを受け取ること(共通の機械読み取り可能な書式に て、適用法により求められる範囲で) </li>
                            <li>Paul Mueller Company でのお客様の個人データ保護に関連する他のお問い合 わせ</li>
                            <li>ご要望は、<a href="mailto:privacy@paulmueller.com">PRIVACY@PAULMUELLER.COM</a> までお送りください。</li>
                        </ul>
                       <p> 当社は、不相応な労力が必要な場合を除き、お客様のご要望に適時に、無料で対処する
よう全力を尽くします。お客様のご要望にお応えする前に、お客様に身分証明書のご提
示をお願いする場合があります。</p>
                    <h3 className="text-primary">サードパーティウェブサイト</h3>
                        <p>本ウェブサイトからリンク経由で閲覧可能なサードパーティウェブサイトには、本プラ
イバシーステートメントは適用されません。</p>
                    <h3 className="text-primary">本プライバシーステートメントの改訂</h3>
                        <p>Paul Mueller Company は、本プライバシーステートメントを改訂する権利を留保しま す。そのため、お客様には、本プライバシーステートメントを定期的に確認し、改訂に 関する情報を得ることをお勧めいたします。最新版は常にウェブサイトでご確認いただ けます。</p>
                </div>
            </div>
            </Col>
            </Row>
            </div>
        </Container>
  
    );
});

export default PrivacyPolicyPageJapanese;
