import React, { memo } from "react";
import {
  Modal as BootstrapModal,
  Button as BootstrapButton,
  Row,
  Col,
} from "react-bootstrap";
import PropTypes from "prop-types";
import deleteIcon from "../../assets/images/Group 1094.svg";

const PromptModel = memo(
  ({
    modalFooterDeleteButton,
    modalFooterCloseButton,
    modelContent,
    animation,
    modelContentIcon,
    deleteButtonVariant,
    open,
    closeAlert,
    callback,
    className,
  }) => {
    return (
      <BootstrapModal
          className="text-center"
          show={open}
          onHide={closeAlert}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          animation={animation}
        >
          <BootstrapModal.Body className={className}>
            <Row>
              <Col>
                <div className="mb-3">
                  <img src={modelContentIcon} alt="" title={modelContentIcon} />
                </div>
                {modelContent}
              </Col>
            </Row>
          </BootstrapModal.Body>
          <BootstrapModal.Footer className="justify-content-center pt-0">
            <BootstrapButton
              variant="outline-info"
              className="text-info mx-3"
              size="lg"
              onClick={closeAlert}
            >
              {modalFooterCloseButton}
            </BootstrapButton>
            <BootstrapButton
              size="lg"
              variant={deleteButtonVariant}
              onClick={callback}
            >
              {modalFooterDeleteButton}
            </BootstrapButton>
          </BootstrapModal.Footer>
        </BootstrapModal>
    );
  }
);

PromptModel.propTypes = {
  /** Footer Delete Button title of Permission Modal  */
  modalFooterDeleteButton: PropTypes.string,
  /** Sizes of Permission Modal  */
  // size: PropTypes.oneOf[("sm", "lg", "xl")],
  /** Animation of Permission Modal */
  animation: PropTypes.bool,
  /** Permission Modal displayed centered on the screen */
  // centered: PropTypes.bool,
  /** Permission Model modelContent */
  modelContent: PropTypes.string,
  /** Permission Model Icon */
  modelContentIcon: PropTypes.node,
  /** Footer Close Button title of Permission Modal  */
  modalFooterCloseButton: PropTypes.string,
  /** Footer Delete Button variant of Permission Modal  */
  deleteButtonVariant: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "danger",
  ]),
  open: PropTypes.bool,
  closeAlert: PropTypes.func,
  callback: PropTypes.func,
  className: PropTypes.any,
};

PromptModel.defaultProps = {
  modelContentIcon: deleteIcon,
  deleteButtonVariant: "danger",
  modalFooterDeleteButton: "Delete",
  modalFooterCloseButton: "Close",
  modelContent: (
    <div className="container p-2">
      <h4>Are you sure want to delete this Canada region?</h4>
      <h5>
        Warning!! Deleting User&apos;s data will compromise all the information of
        the dairies and tanks installed in your premises. You will not be able
        to retrieve the information back. Are you sure you want to continue?
      </h5>
    </div>
  ),
  animation: false,
  open: false,
  closeAlert: () => {},
  callback: () => {},
  className: "text-info mt-3",
  // size : "sm"
};
export default PromptModel;
