import React, { memo } from "react";
import { Row, Col, Modal, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const HEIGHTS = [
  { label: "Inches", value: "in" },
  { label: "Centimeters", value: "cm" }
];

const VOLUMES = [
  { label: "Gallons", value: "gal" },
  { label: "Litres", value: "L" },
  { label: "Pounds", value: "lb" },
  { label: "Kilograms", value: "kg" },
];


const CalibrationReportModel = memo(
  ({ showModel, closeModel, calibrationData }) => {
    const { t } = useTranslation();
    const heightLabel = HEIGHTS.find((height) => height.value === calibrationData.heightUnit)?.label || 'inch';
    const volumeLabel = VOLUMES.find((volume) => volume.value === calibrationData.volumeUnit)?.label || 'gal_usa';
    console.log(calibrationData);
    const tableStructure = [
      { colName: t("tank_height"), dataKey: "AccumulatedHeight", colUnit: t(`${heightLabel}`) },
      { colName: t("volume_increment"), dataKey: "VolumeIncrement", colUnit: t(`${volumeLabel}`) },
      { colName: t("accumulated_volume"), dataKey: "AccumulatedVolume", colUnit: t(`${volumeLabel}`) },
    ];

    return (
      <Modal
        show={showModel}
        onHide={closeModel}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header className="bg-primary" closeButton>
          <Modal.Title className="lead text-capitalize text-white mb-0 font-weight-bold">
            {calibrationData.chartName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="report-model">
            <Col lg={12}>
              <Table responsive borderless className="global-table">
                <thead className="border-bottom">
                  <tr className="text-dark-gray">
                    {tableStructure.map((col, i) => {
                      return (
                        <th className="font-weight-normal" key={i}>
                          {col.colName}<br />
                          ({col.colUnit})
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {calibrationData?.chart !== null &&
                    calibrationData?.chart.map((data, i) => {
                      return (
                        <tr key={i}>
                          <td>{data ? data["Height"] : ''}</td>
                          <td>{data ? data["Incremental Volume"] : ''}</td>
                          <td>{data ? data["Accumulated Volume"] : ''}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Col>
          </Row>
          {calibrationData?.chart === null && calibrationData?.chart < 1 && (
            <div className="text-center lead">{t("data_not_available")}</div>
          )}
        </Modal.Body>
      </Modal>
    );
  }
);

CalibrationReportModel.propTypes = {
  showModel: PropTypes.bool,
  closeModel: PropTypes.func.isRequired,
};

CalibrationReportModel.defaultProps = {
  showModel: false,
};

export default CalibrationReportModel;
