import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import CountryPhoneInput from "../../components/CountryPhoneInput";
import { addRegionalManagers, DisplayDairyData } from "../../actions/actions";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { toast } from "react-toastify";
import Toaster from "../../components/Toaster";
import MultiOptionSelect from "../../components/MultiOptionSelector";

const AddTechnicianForm = ({ showModel, closeModel, updateList }) => {
  const [dairiesOption, setDairiesOptions] = useState([]);
  const [selectDairies, setSelectDairies] = useState([]);
  const { regionID, dealerID } = useParams();

  const { t } = useTranslation();
  const [contact, setContact] = useState("");
  const [selectedContact, setSelectedContact] = useState("");
  const schema = yup.object().shape({
    name: yup.string().required(t("technician_name_error")),
    email: yup.string().max(50).email(t('invalid_email_error')).required(t("technician_email")),
    techDairies: yup.array().of(yup.string()),
    contact: yup.lazy((value) => {
      if (
        value &&
        Object.values(value).some(
          (v) => !(v === null || v === undefined || v === "")
        )
      ) {
        return yup.string().min(10, t("contact_digit"));
      }
      return yup.mixed().notRequired();
    }),
  });

  const { register, handleSubmit, errors, setValue } = useForm({
    validationSchema: schema,
  });

  /** Display assign dairy list of dealers */
  useEffect(() => {
    DisplayDairyData(dealerID).then((response) => {
      setDairiesOptions(response.data);
    });
  }, []);

  // When any dairy select for technician add it into a
  // selected dairies array and remove it from dairies
  const onSelectDairy = (e) => {
    const selectedOptionValue = e.target.value;

    if (selectedOptionValue === 'select_all_dairies') {
      selectDairies.push(...dairiesOption);
      dairiesOption.length = 0;
    }
    else {
      let dairy = selectedOptionValue && dairiesOption.splice(e.target.value, 1);
      dairy.length && selectDairies.push(dairy[0]);
      selectedOptionValue && setDairiesOptions(dairiesOption);
    }

    setSelectDairies([...selectDairies]);
  };

  /** Remove selected dairy function */
  const removeSelectedDairy = (index) => {
    let selectedDairy = selectDairies.splice(index, 1);
    dairiesOption.push(selectedDairy[0]);
    setSelectDairies([...selectDairies]);
  };

  const postTechnicians = (user) => {
    let changedContact = selectedContact.trim();
    user.contact = changedContact === "" ? changedContact : contact;
    user.techDairies = selectDairies.map((dairy) => dairy.id);
    addRegionalManagers({
      ...user,
      createdBy: dealerID,
      region: regionID,
      role: "Technician",
    })
      .then((result) => {
        if (result.status === 200) {
          closeModel();
          updateList(result.data, "add");
          toast.dark(
            <Toaster
              className="text-capitalize"
              icon="notify"
              message={t("technician_added_successfully")}
            />,
            { autoClose: 3000, hideProgressBar: true }
          );
        }
      })
      .catch((err) => {
        if (
          err.response &&
					((err.response.status === 400 && err.response.data && err.response.data.code === 'E_UNIQUE') ||
						(err.response.status === 500 && err.response.data && err.response.data.includes('E_UNIQUE')))
        ) {
          toast.dark(
            <Toaster icon="error" message={t("email_already_exists")} />
          );
        } else {
          toast.dark(
            <Toaster icon="error" message={t("something_went_wrong")} />
          );
        }
      });
  };

  return (
    <Modal
      show={showModel}
      onHide={() => {
        setSelectDairies([]);
        closeModel();
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Form onSubmit={handleSubmit(postTechnicians)}>
        <Modal.Header className="bg-primary" closeButton>
          <Modal.Title className="lead text-capitalize text-white mb-0 font-weight-bold">
            {t("technician")}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <Row>
            <Col lg={6}>
              <Form.Group controlId="formBasicName">
                <Form.Label className="text-capitalize" size="lg">
                  {t("technician_name")} <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  name="name"
                  size="lg"
                  type="text"
                  ref={register()}
                  isInvalid={!!errors.name}
                />
                {errors.name && (
                  <Form.Control.Feedback type="invalid">
                    {errors.name.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group controlId="formBasicPhone">
                <Form.Label className="text-capitalize" size="lg">
                  {t("phone_number")}
                </Form.Label>
                <CountryPhoneInput
                  onChangeVal={(val, country, e, formattedValue) => {
                    let contactNumber =
                      formattedValue &&
                      formattedValue.slice(country.dialCode.length + 1);
                    setContact(formattedValue);
                    setSelectedContact(contactNumber);
                    let formContactVal =
                      val &&
                      val.match(/\d/g).join("").slice(country.dialCode.length);
                    setValue("contact", formContactVal, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                  }}
                  phoneInputValue={contact}
                />
                <Form.Control
                  name="contact"
                  size="lg"
                  type="text"
                  ref={register()}
                  isInvalid={!!errors.contact}
                  className="d-none"
                />
              </Form.Group>
              {errors.contact && (
                <div className="custom-invalid-feedback text-danger">
                  {errors.contact.message}
                </div>
              )}
            </Col>
            <Col lg={12}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="text-capitalize" size="lg">
                  {t("email")} <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  name="email"
                  size="lg"
                  type="email"
                  ref={register()}
                  isInvalid={!!errors.email}
                />
                {errors.email && (
                  <Form.Control.Feedback type="invalid">
                    {errors.email.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col lg={12}>
              <MultiOptionSelect
                accessor="name"
                options={dairiesOption}
                onSelect={onSelectDairy}
                selectedOption={selectDairies}
                removeOption={(index) => removeSelectedDairy(index)}
                inputRef={register}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="pt-0">
          <Button
            variant="primary"
            type="submit"
            className="text-capitalize"
            size="lg"
          >
            {t("create")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

AddTechnicianForm.propTypes = {
  showModel: PropTypes.bool,
  closeModel: PropTypes.func.isRequired,
  dairy: PropTypes.string,
  updateList: PropTypes.any,
};

AddTechnicianForm.defaultProps = {
  showModel: false,
};
export default AddTechnicianForm;
