import React from "react";
import PropTypes from "prop-types";
import { Form, Badge, Image } from "react-bootstrap";
import Close from "../../assets/images/close_Icon.svg";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const MultiSelect = styled.div`
  background: #fafafa;
`;

const MultiOptionSelector = ({
  onSelect,
  options,
  selectedOption,
  removeOption,
  accessor,
  inputRef,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Form.Group controlId="exampleForm.ControlSelect1">
        <Form.Label>{t('assign_sites')}</Form.Label>
        <Form.Control
          as="select"
          size="lg"
          name="techDairies"
          onChange={onSelect}
          inputRef={inputRef}
        >
          <option value="" selected>{t('no_assign_sites')}</option>
          <option value="select_all_dairies">{t('all_sites')}</option>
          {options.map((option, index) => {
            return (
              <option value={index} key={index}>
                {option[accessor]}
              </option>
            );
          })}
        </Form.Control>
      </Form.Group>
      {selectedOption.length !== 0 && (
        <MultiSelect>
          <div className="border p-2 rounded mb-3">
            {selectedOption.map((option, key) => {
              return (
                <Badge
                  key={key}
                  pill
                  variant="light"
                  className="badge-bg-light m-1 p-2"
                >
                  {" "}
                  {option[accessor]}
                  <Image
                    src={Close}
                    className="ml-2"
                    onClick={() => removeOption(key)}
                  />
                </Badge>
              );
            })}
          </div>
        </MultiSelect>
      )}
    </>
  );
};

MultiOptionSelector.propTypes = {
  /** Select dropdown function */
  onSelect: PropTypes.func.isRequired,
  /** Select dropdown option values */
  options: PropTypes.array.isRequired,
  /** Select option values */
  selectedOption: PropTypes.array.isRequired,
  /** Remove selected options */
  removeOption: PropTypes.func.isRequired,
  /** Required for object field selector */
  accessor: PropTypes.string.isRequired,
  inputRef: PropTypes.any,
};

export default MultiOptionSelector;
