import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";
import { AuthContext, useAuthContext } from "./context/auth";
import { ApiContext, useApiContext } from "./context/apiContext";
import { BreadcrumbContext } from "./context/breadcrumbContext";
import { DeviceContext } from "./context/deviceContext";
import { ToastContainer } from "react-toastify";
import Breadcrumb from "./components/Breadcrumb/Breadcrumb";
import Header from "./components/Header";
import routes from "./routes";
import "./axiosConfig";
import BreadcrumbData from "./containers/Breadcrumb/BreadcrumbData";
import MetaTags from "components/MetaTag";

function getHomePageOfUser(user) {
  switch (user.role) {
    case "SuperAdmin":
      return "/regions";
    case "RegionalManager":
      return `/regions/${user.region.id}/dealers`;
    case "Dealer":
      return `regions/dealers/${user.id}/dairies`;
    case "Technician":
      return `regions/dealers/${user.createdBy}/dairies`;
    case "Sanitarian":
      return `regions/dealers/${user.id}/dairies`;
    case "MilkHauler":
      return `regions/dealers/${user.id}/dairies`;
    case "DairyAdmin":
      return `regions/dealers/${user.id}/dairies`;
    case "DairyOperator":
      return `regions/dealers/${user.id}/dairies`;
    default:
      return false;
  }
}

function App() {
  // Check localstorage for existing tokens
  const existingToken = localStorage.getItem("token");
  const existingUser = JSON.parse(localStorage.getItem("user"));

  // Initialize App state with existing token, if exists.
  const [authUser, setAuthUser] = useState({
    user: existingUser,
    token: existingToken,
  });
  const [regionApi, setRegionApi] = useState([]);
  const [regionalManager, setRegionalManager] = useState([]);
  const [dairyApi, setDairyApi] = useState([]);
  const [dairyAdminApi, setDairyAdminApi] = useState([]);
  const [dealerApi, setDealerApi] = useState([]);
  const [techDairyApi, setTechDairyApi] = useState([]);
  const [region, setBreadcrumbRegion] = useState();
  const [dealer, setBreadcrumbDealer] = useState();
  const [dairyFarm, setBreadcrumbDairyFarm] = useState();
  const [deviceTypeGlobal, setDeviceTypeGlobal] = useState();
  const history = useHistory();
  // Extend "setAuthTokens" function to update localstorage when tokens gets changed
  // Pass this function as a context, so that it can be called from anywhere to update the tokens
  const setAuthUserExtended = (user, token) => {
    if (token) {
      user.expire = new Date();
      localStorage.setItem("token", token);
      localStorage.setItem("user", JSON.stringify(user));
    } else {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
    }
    setAuthUser({ user, token });
  };

  useEffect(() => {
    if (authUser.token) {
      const browserHistoryListener = history.listen(location => {
        if (history.action === 'POP') {
          setBreadcrumbRegion();
          setBreadcrumbDealer();
          setBreadcrumbDairyFarm();
        }
      });

      return browserHistoryListener();
    }
  }, [authUser, existingToken]);

  return (
    <AuthContext.Provider
      value={{ authUser, setAuthUser: setAuthUserExtended }}
    >
      <ApiContext.Provider
        value={{
          regionalManager,
          setRegionalManager,
          regionApi,
          setRegionApi,
          dairyApi,
          setDairyApi,
          dairyAdminApi,
          setDairyAdminApi,
          dealerApi,
          setDealerApi,
          techDairyApi,
          setTechDairyApi,
        }}
      >
        <BreadcrumbContext.Provider
          value={{
            region,
            setBreadcrumbRegion,
            dealer,
            setBreadcrumbDealer,
            dairyFarm,
            setBreadcrumbDairyFarm,
          }}
        >
          <DeviceContext.Provider
            value={{
              deviceTypeGlobal,
              setDeviceTypeGlobal
            }}
          >
            {/* <Router> */}
              <Switch>
                {routes.publicRoutes.map((route) => (
                  <Route key={route.path} path={route.path} exact={route.exact}>
                    <MetaTags />
                    <route.component />
                  </Route>
                ))}
                {routes.privateRoutes.map((route, i) => (
                  <PrivateRoute
                    key={i}
                    path={route.path}
                    exact={route.exact}
                    ableToAccess={route.ableToAccess}
                    to={route.to}
                  >
                    <Header />
                    <MetaTags />
                    {route.component ? <route.component /> : null}
                  </PrivateRoute>
                ))}
                <Route path="*">
                  
                  <Redirect to={{ pathname: "/page-not-found" }} />
                </Route>
              </Switch>
            {/* </Router> */}
            <ToastContainer
              position="bottom-right"
              autoClose={false}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
            />
            <BreadcrumbData></BreadcrumbData>
          </DeviceContext.Provider>
        </BreadcrumbContext.Provider>
      </ApiContext.Provider>
    </AuthContext.Provider>
  );
}

// TODO: This, <PrivateRoute> component can have it own separate folder in ./src/components and then exported in this file.
// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, path, exact, ableToAccess, to }) {
  const { authUser } = useAuthContext();
  return (
    <Route
      path={path}
      exact={exact}
      render={(props) => {
        const { location } = props;
        // If not logged in, redirect to /login
        if (!authUser.token) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { referer: location } }}
            />
          );
        }
        // Check if the current user role has access to this route or not
        // If not able to access, redirect to their homepage
        const isAbleToAccess = ableToAccess.includes(authUser.user.role);
        if (!isAbleToAccess) {
          return (
            <Redirect
              to={{
                pathname: getHomePageOfUser(authUser.user),
              }}
            />
          );
        }
        if (to) {
          return <Redirect to={location.pathname + to} />;
        }

        // If able to access, move forward
        return children;
      }}
    />
  );
}
PrivateRoute.propTypes = {
  children: PropTypes.array.isRequired,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  exact: PropTypes.bool.isRequired,
  ableToAccess: PropTypes.array.isRequired,
};

export default App;
