import React, { memo, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import DealerCard from "../../components/DealerCard";
import { useTranslation } from "react-i18next";
import { Row, Col, Button } from "react-bootstrap";
import {
  getDealerList,
  deleteSuperAdmin,
  postResendUserActivationLink,
  getRegions,
} from "../../actions/actions";
import Search from "../../components/Search";
import AddDealerForm from "../../forms/DealerForm/AddDealerForm";
import EditDealerForm from "../../forms/DealerForm/EditDealerForm";
import PromptModel from "components/PromptModel/PromptModel";
import { toast } from "react-toastify";
import { useApiContext } from "../../context/apiContext";
import CardViewLoader from "../../components/CardViewLoader";
import Toaster from "../../components/Toaster";

const DealerTab = memo(() => {
  const { t } = useTranslation();
  const { regionID } = useParams();
  /* set searchTerm data with useState */
  const [show, setShow] = useState(false);
  const [dealers, setDealers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [selectId, setSelectedId] = useState(null);
  const [user, setUser] = useState(null);
  const [display, setDisplay] = useState(false);
  const [dealerData, setDealerData] = useState([]);
  const apiRegion = useApiContext();
  const [regions, setRegions] = useState([]);
  const [cardViewLoader, setCardViewLoader] = useState(false);

  /** Display dealer list in dealer card */
  const DealerList = () => {
    setCardViewLoader(true);
    getDealerList(regionID)
      .then((response) => {
        setCardViewLoader(false);
        const DealerId = response.data.map((i) => i.id);
        localStorage.setItem("Dealers", JSON.stringify(DealerId));
        setDealers(response.data);
      })
      .catch((err) => {
        toast.dark(
          <Toaster
            icon="error"
            message={err?.response?.message ?? t("something_went_wrong")}
          />
        );
      });
  };

  useEffect(() => {
    DealerList();
  }, [regionID]);

  const deleteDealer = (id) => {
    deleteSuperAdmin(id)
      .then((response) => {
        if (response.status === 200) {
          setSelectedId(null);
          setOpen(false);
          toast.dark(
            <Toaster
              className="text-capitalize"
              icon="notify"
              message={t("dealer_deleted_successfully")}
            />,
            { autoClose: 3000, hideProgressBar: true }
          );
          DealerList();
        }
      })
      .catch((err) => {
        console.error(err);
        toast.dark(
          <Toaster
            icon="error"
            message={err?.response?.message ?? t("something_went_wrong")}
          />
        );
      });
  };

  /** User not register when send user activation link functionality */
  const sendUserActivationLink = (email) => {
    postResendUserActivationLink({ email: email })
      .then((response) => {
        if (response.status === 200) {
          toast.dark(
            <Toaster
              className="text-capitalize"
              icon="notify"
              message={t("send_invitation_link")}
            />,
            { autoClose: 3000, hideProgressBar: true }
          );
        }
      })
      .catch((err) => {
        console.error(err);
        toast.dark(
          <Toaster
            icon="error"
            message={err?.response?.message ?? t("something_went_wrong")}
          />
        );
      });
  };

  /** Added and update data after update state data */
  const updatedList = (item, action) => {
    item.region = item.region.id;
    if (action === "add") {
      setDealers([item, ...dealers]);
    }
    if (action === "edit") {
      DealerList();
    }
  };

  const results = dealers && dealers.length 
  ? dealers.filter((card) => {
      return card.name.toLowerCase().includes(searchTerm.toLowerCase());
    })
  : [];

  useEffect(() => {
    if (apiRegion.regionApi.length < 1) {
      getRegions().then((response) => {
        setRegions(response.data);
        apiRegion.setRegionApi(response.data);
      });
    } else {
      setRegions(apiRegion.regionApi);
    }
  }, []);

  return (
    <>
      <Row>
        <Col xs={12} lg={3} md={8} sm={7} className="mb-4">
          <Search
            placeholder={t("search")}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
        </Col>
        <Col
          xs={12}
          lg={9}
          md={4}
          sm={5}
          className="d-inline-flex justify-content-end mb-4"
        >
          <Button
            className="text-capitalize"
            variant="primary"
            onClick={() => setShow(true)}
          >
            {t("add_new_dealer")}
          </Button>
        </Col>
      </Row>
      {cardViewLoader ? (
        <CardViewLoader />
      ) : (
          results.length < 1 ? (
            <div className="text-center lead">{t("data_not_available")}</div>
          ) : (
              <Row>
                {results.map((item, i) => {
                  return (
                    <Col lg={3} md={4} className="mb-4" key={i}>
                      <DealerCard
                        dealer={item}
                        key={"dealerTab" + item.id}
                        editHandlerEvent={(dealerId) => {
                          setDisplay(true);
                          setUser(dealerId);
                          setDealerData(item);
                        }}
                        deleteHandlerEvent={(dealerId) => {
                          setSelectedId(dealerId);
                          setOpen(true);
                        }}
                        activateLinkHandlerEvent={(email) =>
                          sendUserActivationLink(email)
                        }
                      />
                    </Col>
                  );
                })}
              </Row>
            )
        )}

      {show && (
        <AddDealerForm
          showModel={show}
          closeModel={() => setShow(false)}
          updateList={updatedList}
          regions={regions}
        />
      )}
      {display && (
        <EditDealerForm
          showModel={display}
          closeModel={() => setDisplay(false)}
          userId={user}
          dealerData={dealerData}
          updateList={updatedList}
          sendUserActivationLink={(email) => sendUserActivationLink(email)}
          regions={regions}
        />
      )}
      <PromptModel
        open={open}
        closeAlert={() => {
          setOpen(false);
        }}
        modelContent={t("delete_record_msg")}
        callback={() => deleteDealer(selectId)}
      />
    </>
  );
});

export default DealerTab;
