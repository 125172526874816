/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "react-bootstrap";
import _ from "lodash";
import { useParams } from "react-router";
import { Collapse } from "antd";
import { getEnableStatus } from "../../helpers";
import io from "../../actions/socket";

const SettingTable = ({ listWithKeyValue }) => {
  if (!listWithKeyValue?.length) return null;

  const mid = Math.ceil(listWithKeyValue.length / 2);
  const splittedTable = [listWithKeyValue.slice(0, mid), listWithKeyValue.slice(mid)];

  return (
    <div className="d-flex flex-column flex-md-row align-items-start">
      {splittedTable?.map((dataEntries, i) => (
        <Table key={i} className="chiller-setting-table table-bordered" bordered>
          {dataEntries?.map(([key, value], index) => {
            return (
              <tr className="chiller-setting-table-data" key={index}>
                <td className="d-flex justify-content-between">
                  <span>{key}</span>
                  <span>{value}</span>
                </td>
              </tr>
            )
          })}
        </Table>))}
    </div>
  )
};

const StageAccordion = ({ stages }) => {
  if (!stages) return null;
  return (
    <Collapse accordion expandIconPosition="right" bordered={false} className="settings-accordion">
      {stages?.map(({ title, id, ...data }) => (
        <Collapse.Panel key={id} header={title} className="settings-accordion__item">
          <SettingTable listWithKeyValue={Object.entries(data)?.filter(([, value]) => value || value === 0)} />
        </Collapse.Panel>
      ))}
    </Collapse>)
};

const SETTING_TABS = [
  { id: 0, label: 'system_setup' },
  { id: 1, label: 'chiller_setup' },
  { id: 2, label: 'pump_setup' },
  { id: 3, label: 'alarms_alerts' },
  { id: 4, label: 'cooling_setup' }];

const Settings = () => {
  const [data, setData] = useState({})
  const { dairyID, tankID } = useParams();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(SETTING_TABS[0]?.id);
  const { gatewayParameters } = data;
  const chiller = data?.CHILLER?.[Object.keys(data?.CHILLER)?.[0]];


  const settings = (() => {
    if (!tankID || !gatewayParameters) return null;

    switch (activeTab) {
      // System Setup
      case 0: return ({
        [t('date')]: gatewayParameters.date,
        [t('time')]: gatewayParameters.time,
        // [t('timezone')]: '',
        [t('sleep_mode')]: getEnableStatus(gatewayParameters.sleepMode),
        [t('chiller_model')]: gatewayParameters.chillerModel,
        [t('chiller_name')]: gatewayParameters.deviceName,
        // [t('company_name')]: 'API CALL',
        [t('producer_id')]: gatewayParameters.locationName?.producerID,
        [t('milksecure_integration')]: gatewayParameters.deviceRegistrationId,
        // [t('version_details')]: 'API CALL',
        // [t('ip_address')]: 'API CALL',
        [t('mac_address')]: gatewayParameters.macAdd,
        [t('language')]: gatewayParameters.language,
      });

      // Chiller Setup
      case 1: return ({
        [t('compressor_rolling_start')]: getEnableStatus(chiller.CHILLER_CONTROLLER_HOLD_REG_ROLLING_START_ENABLED_PARAM),
        [t('degrees_fc')]: gatewayParameters.degreesFC,
        [t('pressure_unit')]: gatewayParameters.pressureUnit,
        [t('temperature_1_offset')]: chiller?.CHILLER_CONTROLLER_HOLD_REG_TEMP_1_CALIBRATION_OFFSET_DEG_C_PARAM && gatewayParameters.degreesFC ? `${chiller.CHILLER_CONTROLLER_HOLD_REG_TEMP_1_CALIBRATION_OFFSET_DEG_C_PARAM}\u00B0 ${gatewayParameters.degreesFC}` : '-',
        [t('temperature_2_offset')]: chiller?.CHILLER_CONTROLLER_HOLD_REG_TEMP_1_CALIBRATION_OFFSET_DEG_C_PARAM && gatewayParameters.degreesFC ? `${chiller.CHILLER_CONTROLLER_HOLD_REG_TEMP_2_CALIBRATION_OFFSET_DEG_C_PARAM}\u00B0 ${gatewayParameters.degreesFC}` : '-',
        [t('temperature_3_offset')]: chiller?.CHILLER_CONTROLLER_HOLD_REG_TEMP_1_CALIBRATION_OFFSET_DEG_C_PARAM && gatewayParameters.degreesFC ? `${chiller.CHILLER_CONTROLLER_HOLD_REG_TEMP_3_CALIBRATION_OFFSET_DEG_C_PARAM}\u00B0 ${gatewayParameters.degreesFC}` : '-',
        [t('temperature_4_offset')]: chiller?.CHILLER_CONTROLLER_HOLD_REG_TEMP_1_CALIBRATION_OFFSET_DEG_C_PARAM && gatewayParameters.degreesFC ? `${chiller.CHILLER_CONTROLLER_HOLD_REG_TEMP_4_CALIBRATION_OFFSET_DEG_C_PARAM}\u00B0 ${gatewayParameters.degreesFC}` : '-',
        [t('process_flow_meter')]: getEnableStatus(chiller.CHILLER_CONTROLLER_HOLD_REG_CHILLER_FLOW_METER_PRESENT_PARAM),
        [t('process_velocity_max')]: chiller.CHILLER_CONTROLLER_HOLD_REG_PROCESS_MAX_VELOCITY_CMPS_PARAM ? `${chiller.CHILLER_CONTROLLER_HOLD_REG_PROCESS_MAX_VELOCITY_CMPS_PARAM} cm/s` : '-',
        [t('process_velocity_min')]: chiller.CHILLER_CONTROLLER_HOLD_REG_PROCESS_MIN_VELOCITY_CMPS_PARAM ? `${chiller.CHILLER_CONTROLLER_HOLD_REG_PROCESS_MIN_VELOCITY_CMPS_PARAM} cm/s` : '-',
        [t('process_tubing_inside_diameter')]: chiller.CHILLER_CONTROLLER_HOLD_REG_PROCESS_PIPE_DIAMETER_MM_PARAM ? `${chiller.CHILLER_CONTROLLER_HOLD_REG_PROCESS_PIPE_DIAMETER_MM_PARAM}mm` : '-',
        // [t('process_flow_offset')]: '',
        [t('process_flow_unit')]: gatewayParameters.processUnitFlow,

      });

      // Pump Setup
      case 2: return ({
        [t('redundant_pump')]: getEnableStatus(chiller.CHILLER_CONTROLLER_HOLD_REG_REDUNDANT_PUMP_ENABLED_PARAM),
        [t('pump_rotating_start')]: getEnableStatus(chiller.CHILLER_CONTROLLER_HOLD_REG_PUMP_ROTATING_START_ENABLED_PARAM),
        [t('water_level_control')]: getEnableStatus(chiller.CHILLER_CONTROLLER_HOLD_REG_WATER_LEVEL_CONTROL_ENABLED_PARAM),
        [t('high_level_probe_sensitivity')]: chiller.CHILLER_CONTROLLER_HOLD_REG_HIGH_LEVEL_PROBE_TIMEOUT_SEC_PARAM,
        [t('low_level_probe_sensitivity')]: chiller.CHILLER_CONTROLLER_HOLD_REG_LOW_LEVEL_PROBE_TIMEOUT_SEC_PARAM,
        [t('chiller_flow_meter')]: getEnableStatus(chiller.CHILLER_CONTROLLER_HOLD_REG_PROCESS_FLOW_METER_PRESENT_PARAM),
        [t('chiller_velocity_max')]: chiller.CHILLER_CONTROLLER_HOLD_REG_CHILLER_MAX_VELOCITY_CMPS_PARAM ? `${chiller.CHILLER_CONTROLLER_HOLD_REG_CHILLER_MAX_VELOCITY_CMPS_PARAM} cm/s` : '-',
        [t('chiller_velocity_min')]: chiller.CHILLER_CONTROLLER_HOLD_REG_CHILLER_MIN_VELOCITY_CMPS_PARAM ? `${chiller.CHILLER_CONTROLLER_HOLD_REG_CHILLER_MIN_VELOCITY_CMPS_PARAM} cm/s` : '-',
        [t('chiller_solution_pipe_inside_diameter')]: chiller.CHILLER_CONTROLLER_HOLD_REG_CHILLER_PIPE_DIAMETER_MM_PARAM ? `${chiller.CHILLER_CONTROLLER_HOLD_REG_CHILLER_PIPE_DIAMETER_MM_PARAM}mm` : '-',
        // [t('chiller_flow_offset')]: '',
        [t('chiller_flow_unit')]: gatewayParameters.chillerFlowUnit,
      });

      // Alarms/Alerts
      case 3: return ({
        [t('high_temp_alert_1_setpoint')]: gatewayParameters.highTempAlert1Setpoint ? `${gatewayParameters.highTempAlert1Setpoint}\u00B0 ${gatewayParameters.degreesFC}` : '-',
        [t('high_temp_alert_1_sensor')]: gatewayParameters.highTempAlert1Sensor,
        [t('high_temp_alert_2')]: getEnableStatus(gatewayParameters.highTempAlert2),
        [t('high_temp_alert_2_setpoint')]: gatewayParameters.highTempAlert2Setpoint ? `${gatewayParameters.highTempAlert2Setpoint}\u00B0 ${gatewayParameters.degreesFC}` : '-',
        [t('high_temp_alert_2_sensor')]: gatewayParameters.highTempAlert2Sensor,
        [t('high_temp_alert_delay')]: gatewayParameters.highTempAlertDelay ? `${gatewayParameters.highTempAlertDelay} min` : '-',
        [t('high_temp_alert_duration')]: gatewayParameters.highTempAlertDuration ? `${gatewayParameters.highTempAlertDuration} min` : '-',
        [t('low_temp_alert_setpoint')]: gatewayParameters.lowTempAlertSetpoint && gatewayParameters.degreesFC ? `${gatewayParameters.lowTempAlertSetpoint}\u00B0 ${gatewayParameters.degreesFC}` : '-',
        [t('low_temp_alert_1_sensor')]: gatewayParameters.lowTempAlert1Sensor,
        [t('low_temp_alert_2')]: gatewayParameters.lowTempAlert2,
        [t('low_temp_alert_2_setpoint')]: gatewayParameters.lowTempAlert2Setpoint ? `${gatewayParameters.lowTempAlert2Setpoint}\u00B0 ${gatewayParameters.degreesFC}` : '-',
        [t('low_temp_alert_2_sensor')]: gatewayParameters.lowTempAlert2Sensor,
        [t('low_temp_alert_delay')]: gatewayParameters.lowTempAlertDelay ? `${gatewayParameters.lowTempAlertDelay} min` : '-',
        [t('low_temp_alert_duration')]: gatewayParameters.lowTempAlertDuration ? `${gatewayParameters.lowTempAlertDuration} min` : '-',
        [t('flow_switch')]: getEnableStatus(gatewayParameters.flowSwitch),
        [t('water_level_alert')]: getEnableStatus(gatewayParameters.waterLevelAlert),
        [t('water_level_alert_duration')]: gatewayParameters.waterLevelAlertDuration ? `${gatewayParameters.waterLevelAlertDuration} minutes` : '-',
      });

      // Cooling Setup
      case 4: {
        return [1, 2, 3, 4, 5, 6].map((n) => ({
          id: n,
          title: t(`stage_${n}`),
          // Stage 1
          // 'EVC+(Compressor1)': '',
          [t(`stage_${n}`)]: getEnableStatus(chiller[`CHILLER_CONTROLLER_HOLD_REG_STAGE_${n}_ENABLED_PARAM`]),
          [t('cool_setpoint')]: chiller[`CHILLER_CONTROLLER_HOLD_REG_STAGE_${n}_COOL_SETPOINT_DEG_C_PARAM`] && gatewayParameters.degreesFC 
                                ? `${chiller[`CHILLER_CONTROLLER_HOLD_REG_STAGE_${n}_COOL_SETPOINT_DEG_C_PARAM`]}\u00B0 ${gatewayParameters.degreesFC}` 
                                : '-',
          [t('cool_differential')]: chiller[`CHILLER_CONTROLLER_HOLD_REG_STAGE_${n}_COOL_DIFF_DEG_C_PARAM`] && gatewayParameters.degreesFC 
                                    ? `${chiller[`CHILLER_CONTROLLER_HOLD_REG_STAGE_${n}_COOL_DIFF_DEG_C_PARAM`]}\u00B0 ${gatewayParameters.degreesFC}` 
                                    : '-',
          [t('temperature_sensor')]: chiller[`CHILLER_CONTROLLER_HOLD_REG_STAGE_${n}_TEMP_SENSOR_NUM_PARAM`],
          [t('unloader_1_setpoint')]: chiller[`CHILLER_CONTROLLER_HOLD_REG_STAGE_${n}_UNLOADER_1_SETPOINT_DEG_C_PARAM`] && gatewayParameters.degreesFC 
                                      ? `${chiller[`CHILLER_CONTROLLER_HOLD_REG_STAGE_${n}_UNLOADER_1_SETPOINT_DEG_C_PARAM`]}\u00B0 ${gatewayParameters.degreesFC}`
                                      : '-',
          [t('unloader_1_differential')]: chiller[`CHILLER_CONTROLLER_HOLD_REG_STAGE_${n}_UNLOADER_1_DIFF_DEG_C_PARAM`] && gatewayParameters.degreesFC 
                                          ? `${chiller[`CHILLER_CONTROLLER_HOLD_REG_STAGE_${n}_UNLOADER_1_DIFF_DEG_C_PARAM`]}\u00B0 ${gatewayParameters.degreesFC}`
                                          : '-',
          [t('unloader_2_setpoint')]: chiller[`CHILLER_CONTROLLER_HOLD_REG_STAGE_${n}_UNLOADER_2_SETPOINT_DEG_C_PARAM`] && gatewayParameters.degreesFC 
                                      ? `${chiller[`CHILLER_CONTROLLER_HOLD_REG_STAGE_${n}_UNLOADER_2_SETPOINT_DEG_C_PARAM`]}\u00B0 ${gatewayParameters.degreesFC}`
                                      : '-',
          [t('unloader_2_differential')]: chiller[`CHILLER_CONTROLLER_HOLD_REG_STAGE_${n}_UNLOADER_2_DIFF_DEG_C_PARAM`] && gatewayParameters.degreesFC 
                                          ? `${chiller[`CHILLER_CONTROLLER_HOLD_REG_STAGE_${n}_UNLOADER_2_DIFF_DEG_C_PARAM`]}\u00B0 ${gatewayParameters.degreesFC}`
                                          : '-',
          [t('unloader_1')]: getEnableStatus(chiller[`CHILLER_CONTROLLER_HOLD_REG_COMPRESSOR_${n}_UNLOADER_1_ENABLED_PARAM`]),
          [t('unloader_2')]: getEnableStatus(chiller[`CHILLER_CONTROLLER_HOLD_REG_COMPRESSOR_${n}_UNLOADER_2_ENABLED_PARAM`]),
          [t('anti_short_cycle')]:  chiller[`CHILLER_CONTROLLER_HOLD_REG_COMPRESSOR_${n}_ANTI_SHORT_CYCLE_DELAY_SEC_PARAM`] 
                                    ? `${chiller[`CHILLER_CONTROLLER_HOLD_REG_COMPRESSOR_${n}_ANTI_SHORT_CYCLE_DELAY_SEC_PARAM`]} min`
                                    : '-',
          [t('freeze_protect_setpoint')]: chiller[`CHILLER_CONTROLLER_HOLD_REG_COMPRESSOR_${n}_FREEZE_PROTECT_SETPOINT_PSIG_PARAM`] && gatewayParameters.degreesFC 
                                          ? `${chiller[`CHILLER_CONTROLLER_HOLD_REG_COMPRESSOR_${n}_FREEZE_PROTECT_SETPOINT_PSIG_PARAM`]} ${gatewayParameters.pressureUnit}`
                                          : '-',
          [t('freeze_protect_duration')]: chiller[`CHILLER_CONTROLLER_HOLD_REG_COMPRESSOR_${n}_FREEZE_PROTECT_DURATION_PSIG_PARAM`] ? `${chiller[`CHILLER_CONTROLLER_HOLD_REG_COMPRESSOR_${n}_FREEZE_PROTECT_DURATION_PSIG_PARAM`]} sec` : '-',
          [t('unsafe_suction_pressure_setpoint')]:  chiller[`unsafeSuctionPressureSetpoint${n}`] && gatewayParameters.degreesFC 
                                                    ? `${gatewayParameters[`unsafeSuctionPressureSetpoint${n}`]} ${gatewayParameters.pressureUnit}`
                                                    : '-',
          [t('unsafe_suction_pressure_duration')]:  chiller[`unsafeSuctionPressureDuration${n}`] && gatewayParameters.degreesFC 
                                                    ? `${gatewayParameters[`unsafeSuctionPressureDuration${n}`]} sec`
                                                    : '-',
        }));
      }

      default: return null;
    }
  })();


  useEffect(() => {
    io.socket.on(`gateway-data:${tankID}`, (res) => {
      setData(res);
    });
    return () => {
      io.socket.off(`gateway-data:${tankID}`);
    };
  }, [tankID]);

  if (!settings) {
    return (
      <div className="my-4 p-4 rounded live-alerts">
        <div className="m-4 p-4 text-center">{t('data_not_available')}</div>
      </div>
    );
  }

  return (
    <div className="my-4 rounded px-4 py-3 live-alerts">
      {/* Tabs */}
      <div className="mb-3">
        <div className="d-flex flex-column flex-sm-column flex-lg-row settings-tabs">
          {
            SETTING_TABS.map((tab) => {
              return (
                <div
                  className={`settings d-flex ${activeTab === tab.id ? 'settings--active' : ''}`}
                  key={tab.id}
                  role="presentation"
                  onClick={() => {
                    setActiveTab(tab.id)
                  }}>
                  <span>{t(tab.label)}</span>
                </div>
              )
            })
          }
        </div>
      </div>
      {/* Tab Content */}
      <div className="mb-3 pt-3">
        <div className="d-flex flex-column flex-sm-column justify-content-between">
          {activeTab === 4 ? <StageAccordion stages={settings} /> : <SettingTable listWithKeyValue={Object.entries(settings)?.filter(([, value]) => value || value === 0)} />}
        </div>
      </div>
    </div>
  );
};


export default Settings;

