import React, {
  useState,
  useCallback,
  Fragment,
  useRef,
  useEffect,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AsyncTypeahead, MenuItem, Menu } from "react-bootstrap-typeahead";
import { getUser } from "../../actions/actions";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useBreadcrumbContext } from "../../context/breadcrumbContext";
import { rootApi } from "../../axiosConfig";
import _ from 'lodash'

const Container = styled.div`
  .global-search{
  * {
  scrollbar-width: 3px;
  scrollbar-color: #adcdff transparent;
  }
  *::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  }
  *::-webkit-scrollbar-track {
  background: transparent;        
  }
  *::-webkit-scrollbar-thumb {
  background-color: #adcdff;   
  border-radius: 1px;     
  }
  .dropdown-item :active{
    color: 'black',
    backgroundColor: '#F0F6FF'
  }
  .dropdown-item.active{
      color: black;
      background-color: #F0F6FF;
  }
  
}
.global-search :focus{
  width: 17rem;
}


`;

const GlobalSearch = () => {
  const headers = {
    headers: {
      token: localStorage.getItem("token"),
    },
  };
  const { t } = useTranslation();
  const {
    setBreadcrumbRegion,
    setBreadcrumbDealer,
    setBreadcrumbDairyFarm,
  } = useBreadcrumbContext();
  const history = useHistory();
  const ref = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [searchResults, setResults] = useState();
  const location = useLocation();

  useEffect(() => {
    if (location.search === "") {
      ref.current.clear();
    }
  }, [location]);

  const filterByCallback = (option, props) => {
    return (option.name.toLowerCase().indexOf(props.text.toLowerCase()) !== -1 || option.county.toLowerCase().indexOf(props.text.toLowerCase()) !== -1);
  };

  /** Search Dealer functions */

  const searchDealers = async (keyword, orderBy = "name", order = "DESC") => {
    const { role, region, id } = getUser();
    // let paramsOptions = `{"and":[{"role":"Dealer"},{"name":{"contains":"${keyword}"}}]}`;
    let paramsOptions = `{"role":"Dealer","name":{"contains":"${keyword}"}}`;

    if (role !== "SuperAdmin" && region && region.id !== "") {
      paramsOptions = `{"and":[{"role":"Dealer"},{"region":"${region.id}"},{"name": {"contains":"${keyword}"}}]}`;
    }

    const url = `/dealers/search?select=name,region,role&sort=${orderBy} ${order}&where=${paramsOptions}`;

    try {
      const res = await rootApi.get(`${url}`);
      return res.data;
    } catch (err) {
      console.error(err);
      return {};
    }
  };
  /** Search dairy functions */
  const searchDairies = async (keyword, orderBy = "name", order = "ASC") => {
    const { role, id } = getUser();
    let paramsOptions = `{"or":[{"name":{"contains":"${keyword}"}},{"county":{"contains":"${keyword}"}}]}`;
    const DealersId = localStorage.getItem("Dealers");

    if (role !== "SuperAdmin" && id !== "") {
      paramsOptions = `{ "and": [
          { "createdBy": { "in": ${DealersId}} },
          { "or": [
              { "name": { "contains": "${keyword}" } },
              { "county": { "contains": "${keyword}" } }
            ]}
        ]}`;
    }
    const url = `/dairies/search?select=name,createdBy,county&sort=${orderBy} ${order}&where=${paramsOptions}`;

    try {
      const res = await rootApi.get(`${url}`);
      return res.data;
    } catch (err) {
      console.error(err);
      return {};
    }
  };

  const handleSearch = useCallback((query) => {
    setIsLoading(true);
    Promise.all([searchDealers(query), searchDairies(query)])
      .then(([DealerList, DairyList]) => {
        let DealerListData = [];
        let DairyListData = [];
        if (DealerList.length > 0) {
          DealerListData = DealerList.map((i) => ({
            type: "Dealers",
            id: i.id,
            name: i.name,
            subTitle: i.region ? i.region.name : "--",
            regionId: i.region ? i.region.id : false,
            county: ""
          }));
        }
        if (DairyList.length > 0) {
          DairyListData = DairyList.map((i) => {
            return {
              type: "Site",
              id: i.createdBy ? i.createdBy.id : false,
              name: i.name,
              subTitle: i.createdBy ? i.createdBy.name : "--",
              regionId: i.createdBy ? i.createdBy.region : false,
              county: i.county ? i.county : ""
            };
          });
        }
        const options = [...DealerListData, ...DairyListData];
        setResults(options);
        setIsLoading(false);
      })
      .catch((err) => console.error(err));
  });

  /** Group by functionality */
  const groupBy = (data, key) => {
    const allowedKeys = ['Site', 'Dealers'];

    return data.reduce((acc, item) => {
      /** Check if item key is present in allowedKeys array */
      if (allowedKeys.includes(item[key])) {
        (acc[item[key]] = acc[item[key]] || []).push(item);
      }
      return acc;
    }, {});
  };

  /** Render option menu in search functionality */
  const renderMenu = (results, menuProps, state) => {
    let index = 0;
    const groups = groupBy(results, "type");
    const items = Object.keys(groups).map((t) => (
      <Fragment key={t}>
        {index !== 0 && <Menu.Divider className="m-0" />}
        <Menu.Header className="text-primary" style={{ color: "#3f7cca" }}>
          {t}
        </Menu.Header>
        {groups[t].map((i) => {
          const item = (
            <MenuItem key={index} option={i} position={index}>
              <div search={state.text} className="text-truncate">
                {i.name}
              </div>
              <div
                className="text-muted text-truncate"
                style={{ fontSize: "0.8rem" }}
              >
                {i.subTitle}
              </div>
            </MenuItem>
          );

          index += 1;
          return item;
        })}
      </Fragment>
    ));

    return <Menu {...menuProps}>{items}</Menu>;
  };

  /** Get data after redirect particular page functionality */
  const redirectPage = (item) => {
    const { role } = getUser();
    if (item.length > 0) {
      const i = item[0];
      if (role === "SuperAdmin" || role === "RegionalManager") {
        if (i.type === "Dealers" && i.regionId && i.regionId !== "") {
          const url = `/regions/${i.regionId}/dealers?q=${i.name}`;
          history.push(url);
        } else if (
          i.type === "Site" &&
          i.id &&
          i.regionId &&
          i.regionId !== ""
        ) {
          const url = `/regions/${i.regionId}/dealers/${i.id}/dairies?q=${i.name}`;
          history.push(url);
        } else if (
          i.type === "Site" &&
          i.regionId === false &&
          i.id === false
        ) {
          const url = `/regions`;
          history.push({
            pathname: url,
            search: `q=${i.name}&independentDairy=${true}`,
          });
        } else if (i.type === "Dealers" && i.regionId === false) {
          const url = `/regions`;
          history.push({
            pathname: url,
            search: `q=${i.name}&independentDealers=${true}`,
          });
        } else if (
          (i.type === "Site" && i.regionId === undefined) ||
          i.regionId === null
        ) {
          const url = `/regions/dealers/${i.id}/dairies`;
          history.push({
            pathname: url,
            search: `q=${i.name}&independentDealers=${true}`,
          });
        }
      }
      ref.current.clear();
      ref.current.blur();
      //Clearing this so that When user redirects using global search old region dealer dairy name does not come
      setBreadcrumbRegion();
      setBreadcrumbDealer();
      setBreadcrumbDairyFarm();
    }
  };
  const onBlur = (e) => {
    if (ref.current.inputNode.value === "") {
    } else {
      ref.current.inputNode.className =
        ref.current.inputNode.className + " " + "text-muted";
    }
  };
  const onInputChange = (text) => {
    if (text === "") {
      setResults([]);
    } else {
      setTimeout(handleSearch(text), 200);
    }
  };
  return (
    <Container>
      <AsyncTypeahead
        id="search-f"
        onSearch={() => []}
        className="global-search"
        isLoading={isLoading}
        labelKey="name"
        promptText="Searching..."
        options={searchResults}
        placeholder={t("search")}
        renderMenu={renderMenu}
        filterBy={filterByCallback}
        onChange={redirectPage}
        minLength={1}
        ref={ref}
        useCache={false}
        onBlur={onBlur}
        onInputChange={_.debounce(onInputChange, 1000, { leading: false, trailing: true })}
      />
    </Container>
  );
};
export default GlobalSearch;
