import React, { memo, useEffect, useState } from "react";
import { Row, Col, Button, Image, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Search from "../../components/Search";
import Delete from "../../assets/images/delete.svg";
import FileView from "../../assets/images/file_view.svg";
import AddNewChartForm from "../../forms/ChartForm/AddNewChartForm";
import PromptModel from "components/PromptModel/PromptModel";
import TableViewLoader from "../../components/TableViewLoader";
import CalibrationReportModel from "../../components/CalibrationReportModel/CalibrationReportModel";

import {
  getCalibrationListing,
  deleteCalibrationChart,
} from "../../actions/actions";
import { toast } from "react-toastify";
import Toaster from "../../components/Toaster";

const CalibrationChartTab = memo(({ activeTab }) => {
  const { t } = useTranslation();
  const { dealerID } = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [show, setShow] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [selectId, setSelectedId] = useState(null);
  const [open, setOpen] = useState(false);
  const [showCalibration, setShowCalibration] = useState(false);
  const [tableViewLoader, setTableViewLoader] = useState(false);
  const [calibrationData, setCalibrationData] = useState([]);

  const tableStructure = [
    { colName: t("chart_name"), dataKey: "chartName" },
    { colName: t("type"), dataKey: "manufacturer" },
    { colName: t("manufacturer"), dataKey: "tankType" },
  ];

  /** Display Calibration listing */
  const getCalibrationListings = () => {
    setTableViewLoader(true);
    getCalibrationListing(dealerID)
      .then((response) => {
        setTableViewLoader(false);
        setSearchResults(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => { 
    if(activeTab === "calibration_charts") {
      getCalibrationListings();
    }
  }, [activeTab]);

  const results = searchResults.filter((table) => {
    return table.chartName.toLowerCase().includes(searchTerm.toLowerCase());
  });

  /** Delete calibration chart function */
  const deleteCalibration = (id) => {
    deleteCalibrationChart(id)
      .then((response) => {
        if (response.status === 200) {
          setSelectedId(null);
          setOpen(false);
          toast.dark(
            <Toaster
              icon="notify"
              message={t("calibration_deleted_successfully")}
            />,
            { autoClose: 3000, hideProgressBar: true }
          );
          getCalibrationListings();
        }
      })
      .catch((err) => {
        console.error(err);
        toast.dark(
          <Toaster
            icon="error"
            message={err?.response?.message ?? t("something_went_wrong")}
          />
        );
      });
  };

  /** Updated data display direct on list */
  const updateList = (item, action) => {
    if (action === "add") {
      setSearchResults([item, ...searchResults]);
    }
  };

  return (
    <>
      <Row>
        <Col xs={12} lg={3} md={8} sm={7} className="mb-4">
          <Search
            placeholder={t("search")}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
        </Col>
        <Col
          xs={12}
          lg={9}
          md={4}
          sm={5}
          className="d-inline-flex justify-content-end mb-4"
        >
          <Button
            className="text-capitalize"
            variant="primary"
            onClick={() => setShow(true)}
          >
            {t("add_new_chart")}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table responsive borderless className="global-table">
            <thead className="border-bottom">
              <tr className="text-dark-gray">
                {tableStructure.map((col, i) => {
                  return (
                    <th className="font-weight-normal" key={i}>
                      {col.colName}
                    </th>
                  );
                })}
              </tr>
            </thead>
            {tableViewLoader ? (
              <TableViewLoader />
            ) : (
                results.length < 1 ? (
                  <tbody>
                    <tr>
                      <td className="py-2 text-center lead" colspan="5">
                        {t("data_not_available")}
                      </td>
                    </tr>
                  </tbody>
                ) : (
                    <tbody>
                      {results.map((data) => {
                        return (
                          <tr key={data.id}>
                            <td style={{ maxWidth: "500px" }} className="text-truncate">
                              {data.chartName}
                            </td>
                            <td style={{ maxWidth: "180px" }} className="text-truncate">
                              {data.tankType === "HORIZONTAL"
                                ? "Horizontal"
                                : "Vertical"}
                            </td>
                            <td>
                              {data.manufacturer === "NON-MUELLER"
                                ? "Non - Mueller"
                                : "Mueller"}
                            </td>

                            <td className="pt-1" style={{ width: "2px" }}>
                              <Button
                                variant="link"
                                className="table-edit-button text-capitalize text-black"
                                onClick={() => {
                                  setCalibrationData(data);
                                  setShowCalibration(true);
                                }}
                              >
                                <Image src={FileView} />
                              </Button>
                            </td>
                            <td className="pt-1" style={{ width: "2px" }}>
                              <Button
                                variant="link"
                                className="delete-button text-danger text-capitalize"
                                onClick={() => {
                                  setSelectedId(data.id);
                                  setOpen(true);
                                }}
                              >
                                <Image src={Delete} />
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )
              )}
          </Table>
        </Col>
      </Row>

      {show && (
        <AddNewChartForm
          dealerID={dealerID}
          showModel={show}
          closeModel={() => setShow(false)}
          updateList={updateList}
        />
      )}
      <PromptModel
        open={open}
        closeAlert={() => {
          setOpen(false);
        }}
        modelContent={t("delete_record_msg")}
        callback={() => deleteCalibration(selectId)}
      />

      {showCalibration && (
        <CalibrationReportModel
          showModel={showCalibration}
          closeModel={() => setShowCalibration(false)}
          calibrationData={calibrationData}
        />
      )}
    </>
  );
});

export default CalibrationChartTab;
