import React, { useEffect, useState } from "react";
import { useBreadcrumbContext } from "../../context/breadcrumbContext";
import { useTranslation } from "react-i18next";
import {
  fetchRegionById,
  fetchDairyById,
  fetchUserById,
} from "../../actions/actions";

const BreadcrumbData = (props) => {
  const {
    region,
    dealer,
    dairyFarm,
    setBreadcrumbRegion,
    setBreadcrumbDealer,
    setBreadcrumbDairyFarm,
  } = useBreadcrumbContext();
  if (props.type === "regions") {
    if (region) return region;
    else
      return (
        <FetchBreadcrumb
          id={props.match.params.regionID}
          type="regions"
          setName={setBreadcrumbRegion}
        />
      );
  } else if (props.type === "dealers") {
    if (dealer) return dealer;
    else
      return (
        <FetchBreadcrumb
          id={props.match.params.dealerID}
          type="dealers"
          setName={setBreadcrumbDealer}
        />
      );
  } else if (props.type === "dairyFarm") {
    // if (dairyFarm) return dairyFarm;
    // else
      return (
        <FetchBreadcrumb
          id={props.match.params.dairyID}
          type="dairyFarm"
          setName={setBreadcrumbDairyFarm}
        />
      );
  } else {
    return <span />;
  }
};

export default BreadcrumbData;

const FetchBreadcrumb = ({ id, type, setName }) => {
  const { t } = useTranslation();
  const [breadcrumb, setBreadcrumb] = useState();
  const getBreadcrumb = async () => {
    if (type === "regions") {
      try {
        const res = await fetchRegionById(id);
        setBreadcrumb(res.data.name);
        setName(res.data.name);
      } catch (error) {
        console.error(error);
        setBreadcrumb("Regions");
      }
    }
    if (type === "dealers") {
      try {
        const res = await fetchUserById(id);
        setBreadcrumb(res.data.name);
        setName(res.data.name);
      } catch (error) {
        console.error(error);
        setBreadcrumb("Dealers");
      }
    }
    if (type === "dairyFarm") {
      try {
        const res = await fetchDairyById(id);
        setBreadcrumb(res.data.name);
        setName(res.data.name);
      } catch (error) {
        console.error(error);
        setBreadcrumb(`${t('sites')}`);
      }
    }
  };
  useEffect(() => {
    getBreadcrumb();
  }, []);
  return breadcrumb ? breadcrumb : false;
};
