import React, { memo, useEffect, useState } from "react";
import { Row, Col, Button, Form, Modal, Image, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CountryPhoneInput from "../../components/CountryPhoneInput";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { updateUser } from "../../actions/actions";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Toaster from "../../components/Toaster";
import Email from "../../assets/images/email.svg";
import Success from "../../assets/images/check-circle.svg";

const EditSuperAdminForm = memo(
  ({
    showModel,
    closeModel,
    userId,
    updateList,
    superAdminData,
    sendUserActivationLink,
  }) => {
    const { t } = useTranslation();
    const [contact, setContact] = useState("");
    const [selectedContact, setSelectedContact] = useState("");
    const [toggle, setToggle] = useState(true);

    const schema = yup.object().shape({
      name: yup.string().required(t("name_error")),
      email: yup.string().max(50).email(t('invalid_email_error')).required(t("email_error")),
      contact: yup.lazy((value) => {
        if (
          value &&
          Object.values(value).some(
            (v) => !(v === null || v === undefined || v === "")
          )
        ) {
          return yup.string().min(10, t("contact_digit"));
        }
        return yup.mixed().notRequired();
      }),
    });
    const [user, setUser] = useState(superAdminData);

    const { register, handleSubmit, errors, setValue, reset } = useForm({
      mode: "onChange",
      validationSchema: schema,
    });

    useEffect(() => {
      if (user) {
        let formContactVal = "";
        let formContactValue = "";
        if (user.contact !== "") {
          formContactVal = user.contact && user.contact.split(" ");
          formContactVal.shift();
          if (formContactVal.length) {
            formContactValue = formContactVal.join(" ").match(/\d/g).join("");
            setSelectedContact(formContactVal.join(" "));
          }
        }
        setContact(user.contact);
        reset(user);
      }
    }, [user]);

    const postUser = (data) => {
      let changedContact = selectedContact.trim();
      data.contact = changedContact === "" ? changedContact : contact;
      updateUser(userId, data)
        .then((result) => {
          if (result.status === 200) {
            closeModel();
            updateList(result.data, "edit");
            toast.dark(
              <Toaster
                className="text-capitalize"
                icon="notify"
                message={t("super_admin_updated_successfully")}
              />,
              { autoClose: 3000, hideProgressBar: true }
            );
          }
        })
        .catch((err) => {
          if (
            err.response &&
					((err.response.status === 400 && err.response.data && err.response.data.code === 'E_UNIQUE') ||
						(err.response.status === 500 && err.response.data && err.response.data.includes('E_UNIQUE')))
          ) {
            toast.dark(
              <Toaster icon="error" message={t("email_already_exists")} />
            );
          } else {
            toast.dark(
              <Toaster icon="error" message={t("something_went_wrong")} />
            );
          }
        });
    };
    return (
      <Modal
        show={showModel}
        onHide={closeModel}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Form onSubmit={handleSubmit(postUser)}>
          <Modal.Header className="bg-primary" closeButton>
            <Modal.Title className="lead text-capitalize text-white mb-0 font-weight-bold">
              {t("super_admin")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pb-0">
            <Row>
              <Col lg={6}>
                <Form.Group controlId="formBasicName">
                  <Form.Label className="text-capitalize" size="lg">
                    {t("name")} <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    name="name"
                    size="lg"
                    type="text"
                    ref={register()}
                    isInvalid={!!errors.name}
                  />
                  {errors.name && (
                    <Form.Control.Feedback type="invalid">
                      {errors.name.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group controlId="formBasicPhone">
                  <Form.Label className="text-capitalize" size="lg">
                    {t("phone_number")}
                  </Form.Label>

                  <CountryPhoneInput
                    onChangeVal={(val, country, e, formattedValue) => {
                      let contactNumber =
                        formattedValue &&
                        formattedValue.slice(country.dialCode.length + 1);
                      setContact(formattedValue);
                      setSelectedContact(contactNumber);
                      let formContactVal =
                        val &&
                        val
                          .match(/\d/g)
                          .join("")
                          .slice(country.dialCode.length);
                      setValue("contact", formContactVal, {
                        shouldValidate: true,
                        shouldDirty: true,
                      });
                    }}
                    phoneInputValue={user.contact}
                  />
                  <Form.Control
                    name="contact"
                    size="lg"
                    type="text"
                    ref={register()}
                    isInvalid={!!errors.contact}
                    className="d-none"
                  />
                </Form.Group>
                {errors.contact && (
                  <div className="custom-invalid-feedback text-danger">
                    {errors.contact.message}
                  </div>
                )}
              </Col>
              <Col lg={12}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label className="text-capitalize" size="lg">
                    {t("email")} <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    name="email"
                    size="lg"
                    type="email"
                    ref={register()}
                    isInvalid={!!errors.email}
                  />
                  {errors.email && (
                    <Form.Control.Feedback type="invalid">
                      {errors.email.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>

          <Modal.Footer className="pt-2 px-2 justify-content-start d-block">
            <Row className="m-0">
              <Col lg={7} className="mb-1">
                {user.isActive === false ? (
                  <Alert
                    variant="warning"
                    className="border-warning px-3 py-2 mb-1 d-flex align-items-center"
                  >
                    <Image src={Email} className="text-top mr-2" />{" "}
                    <span className="text-dark mr-3">
                      {t("user_has_been_not_registered")}
                    </span>
                    {toggle ? (
                      <Button
                        variant="outline-warning"
                        className="ml-4 mb-0 text-capitalize"
                        size="sm"
                        onClick={() => {
                          sendUserActivationLink(user.email);
                          setToggle(false);
                        }}
                      >
                        {t("resend_link")}
                      </Button>
                    ) : (
                      <Button
                        variant="outline-warning"
                        className="ml-5 mb-0 text-capitalize border-0 send-button d-flex align-items-center"
                        size="sm"
                      >
                        <Image src={Success} className="py-1 mr-2" />
                        {t("sent")}
                      </Button>
                    )}
                  </Alert>
                ) : (
                  ""
                )}
              </Col>
              <Col
                lg={5}
                className="mb-1 d-flex align-items-baseline justify-content-end"
              >
                <Button
                  variant="primary"
                  type="submit"
                  className="text-capitalize"
                  size="lg"
                >
                  {t("update")}
                </Button>
              </Col>
            </Row>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
);

EditSuperAdminForm.propTypes = {
  showModel: PropTypes.bool,
  closeModel: PropTypes.func.isRequired,
  userId: PropTypes.any.isRequired,
  updateList: PropTypes.any,
};

EditSuperAdminForm.defaultProps = {
  showModel: false,
};

export default EditSuperAdminForm;
