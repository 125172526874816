import React, { memo } from "react";
import { Container, Image, Row, Col } from "react-bootstrap";
import MuellerLogo from "../../assets/images/Mueller_dark_logo.svg";
import backarrow from "../../assets/images/black-md-arrow.svg";

const PrivacyPolicyPageDutch = memo(() => {
    return(
        <Container>
            
            <div className="mb-5 mt-5  ">
                <Row>
                    <Col
                        xs={{ span: 1, offset: 0 }}
                        sm={{ span: 1, offset: 0 }}
                        md={{ span: 1, offset: 0 }}
                        lg={{ span: 1, offset: 0 }}
                        className="pl-3"
                    ><img src={backarrow} className="mt-2 p-3 text-center back-arrow-hover rounded" style={{cursor: 'pointer'}} onClick={() => {window.history.back()}} /></Col>
                <Col
                    xs={{ span: 8, offset: 1 }}
                    sm={{ span: 10, offset: 0 }}
                    md={{ span: 10, offset: 0 }}
                    lg={{ span: 10, offset: 0 }}
                    className="text-center mr-1">
                        {/* <img  src={MuellerLogo} className="logo-style-privacy-policy-page" /> */}
                </Col>
                </Row>
                <Row>
                <Col
                xs={{ span: 12, offset: 0 }}
                sm={{ span: 12, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
                >
                <div className="mt-5 col-12">
                    <h2>Privacybeleid</h2>
                </div>

                <div>
                <div className="col-lg-12 col-md-12 col-sm-12 mt-3" >
                    <h3 className="text-primary">Deze website wordt beheerd door Paul Mueller Company.</h3>
                        <p>Wanneer u de website van Paul Mueller Company en de producten en diensten gebruikt die daarop kunnen worden gevonden, dan laat u gegevens achter. Bovendien laat de website een aantal browsercookies achter op uw computer. Deze worden gebruikt om ervoor te zorgen dat de website beter werkt op uw apparaat en om u een waardevollere gebruikerservaring te bieden.</p>
                        <p>Hieronder kunt u lezen welke gegevens Paul Mueller Company verzamelt, waarom wij dat doen en wat wij eventueel met die gegevens kunnen doen. U kunt er ook lezen welke cookies wij gebruiken en waarom. Deze verklaring betreft onze volledige website.</p>
                    <h3 className="text-primary">Cookies</h3>
                        <p>Om deze website beter en sneller te laten functioneren en om hem aan te passen aan uw voorkeuren, wordt er bepaalde informatie over uw gebruik van deze website verzameld. Deze informatie kan worden verzameld door gebruik te maken van wat over het algemeen 'cookies' worden genoemd. Dit zijn kleine tekstbestanden die automatisch op uw computer worden geplaatst door de bezochte webpagina. U kunt het gebruik van 'cookies' uitschakelen via uw browserinstellingen. U kunt de 'cookies' naar eigen wens verwijderen via de cookiebeheerfunctionaliteit van uw browser. Dit leidt echter wel tot een gebruikerservaring op deze website die niet geheel optimaal is. Derden kunnen ook cookies plaatsen en vergelijkbare persoonlijke gegevens verzamelen.</p>
                    <h3 className="text-primary">Hoe komen wij aan uw gegevens?</h3>
                       <p>Wanneer u de website van Paul Meuller Company gebruikt, laat u op de volgende manieren persoonlijke gegevens bij ons achter:</p>
                       <p> Automatisch verzamelde gegevens door te navigeren door de website, zoals identificatiecookies, apparaattype, browsertype en pagina's op de site die u heeft bezocht.<br />
                        Vrijwillig door u verstrekte informatie wanneer u berichten verstuurd via de contactformulieren of andere internetformulieren activeert op de website.</p>
                    <h3 className="text-primary">Wat doen wij met uw gegevens?</h3>
                        <p>Paul Mueller Company bewaart en verwerkt uw gegevens voor de volgende doeleinden:</p>
                        <ul>
                            <li>Verzamelen en analyseren van statistieken om de website te optimaliseren</li>
                            <li>De producten en diensten leveren die u van ons koopt</li>
                            <li>Een historisch overzicht van contactenmomenten opbouwen</li>
                            <li>Mailings en nieuwsbrieven kunnen versturen</li>
                        </ul>
                    <h3 className="text-primary">Hoe beveiligen wij uw gegevens?</h3>
                        <p>Om uw gegevens te beschermen, nemen we gepaste maatregelen overeenkomstig de toepasselijke wet- en regelgeving op het gebied van gegevensbescherming en -beveiliging. Daartoe behoort ook dat wij van onze dienstverleners eisen dat zij gepaste maatregelen nemen om de vertrouwelijkheid en veiligheid van uw gegevens te beschermen. Afhankelijk van de ontwikkelingen op dit gebied, de kosten van het implementeren en de aard van de te beschermen gegevens, passen wij technische en organisatorische maatregelen toe ter voorkoming van risico's als vernietiging, verlies, wijziging, ongeautoriseerde bekendmaking of toegang tot uw gegevens.</p>
                    <h3 className="text-primary">Met wie deelt Paul Mueller Company uw gegevens?</h3>
                        <p>Paul Mueller Company behandelt uw gegevens vertrouwelijk. We delen uw gegevens alleen met ons vertrouwde netwerk van vertegenwoordigers van de fabrikant van Paul Mueller Company en geautoriseerde dealers wanneer dit nodig is om u de gevraagde producten of diensten te leveren.</p>
                        <p>We delen een beperkte set persoonsgegevens van MilkSecure™ gebruikers met melktransporteurs, de lokale melkveebedrijfsapparatuurdealer en inspecteurs wanneer dit nodig is voor het ophalen, onderhouden van apparatuur of het waarborgen van de naleving van de geldende regelgeving.</p>
                        <p>In geen geval verkopen wij uw gegevens aan een andere partij zonder uw uitdrukkelijke toestemming.</p>
                    <h3 className="text-primary">Contact, mailings en/of reclame</h3>
                        <p>Wanneer u post of ongewenste reclame mocht ontvangen, kunt u altijd aangegeven dat u niet wilt dat wij nog langer contact met u opnemen. Er zijn geen kosten verbonden aan het afmelden.</p>
                    <h3 className="text-primary">Jonger dan 16 jaar?</h3>
                       <p>Bent u minderjarig, dat wil zeggen jonger dan 16 jaar, dan mag u alleen instemmen met het verwerken van uw persoonlijke gegevens met de goedkeuring van een van uw ouders of een voogd. Bent u jonger dan 16, dan is het belangrijk dat uw ouder(s) of uw voogd deze verklaring lezen/leest. Zij kunnen ook de rechten uitoefenen die u heeft met betrekking tot de persoonlijke gegevens die u instuurt, zoals het recht om u te verzetten tegen de (verdere) verwerking van uw persoonlijke gegevens of het recht om uw gegevens in te zien en te corrigeren.</p>
                    <h3 className="text-primary">Wat kunt u doen met uw gegevens?</h3>
                        <p>Wij streven ernaar u toegang te verschaffen tot uw gegevens. Over het algemeen kunt u zelf uw gegevens beheren (bijvoorbeeld door in te loggen in uw account) en deze bijwerken, aanpassen of, als dat wettelijk mogelijk is, verwijderen. In dit geval raden we u met klem aan uw eigen gegevens te beheren.</p>
                        <p>U kunt altijd contact met ons opnemen als u:</p>
                        <ul>
                            <li>de gegevens die u aan ons heeft verstrekt wilt bekijken, wijzigen of verwijderen (voor zover Paul Mueller Company niet anderszins gerechtigd of verplicht is dergelijke gegevens te bewaren);</li>
                            <li>bezwaar wilt maken tegen bepaalde manieren van verwerken van gegevens (bijvoorbeeld afmelden voor marketingberichten);</li>
                            <li>een kopie van uw gegevens wilt ontvangen (in een leesbare vorm, voor zover dit door de toepasselijk wet wordt vereist); </li>
                            <li>andere vragen wilt stellen over de bescherming van uw gegevens bij Paul Mueller Company.</li>
                            <li>U kunt uw verzoek richten aan <a href="mailto:privacy@paulmueller.com">PRIVACY@PAULMUELLER.COM</a>.</li>
                        </ul>
                       <p>Wij zullen ons best doen uw verzoek tijdig en gratis te beantwoorden, behalve wanneer dit onevenredig veel moeite zou kosten. In bepaalde gevallen kunnen wij u vragen uw identiteit te bewijzen voor wij uw verzoek in behandeling nemen.</p>
                    <h3 className="text-primary">Websites van derden</h3>
                        <p>Dit privacybeleid is niet van toepassing op websites van derden die via links kunnen worden bezocht vanaf deze website.</p>
                    <h3 className="text-primary">Wijzigingen in dit privacybeleid</h3>
                        <p>Paul Mueller Company behoudt zich het recht voor wijzigingen aan te brengen in dit privacybeleid. Wij raden u daarom aan dit privacybeleid regelmatig te lezen, zodat u op de hoogte bent van deze wijzigingen. De meest recente versie kan altijd worden geraadpleegd via de website.</p>
                </div>
            </div>
            </Col>
            </Row>
            </div>
        </Container>
  
    );
});

export default PrivacyPolicyPageDutch;
