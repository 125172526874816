import React, { memo, useState, useEffect } from "react";
import { Row, Col, Button, Table, Image } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PromptModel from "../../components/PromptModel";
import AddFarmUserForm from "../../forms/FarmUserForm/AddFarmUserForm";
import EditFarmUserForm from "../../forms/FarmUserForm/EditFarmUserForm";
import {
  deleteSuperAdmin as deleteFarmData,
  fetchDairy as fetchFarmUsers,
  postResendUserActivationLink,
  unassignFarmUser,
  deleteOrUnAssignFarmUser,
  getUser
} from "../../actions/actions";
import Edit from "../../assets/images/edit.svg";
import Delete from "../../assets/images/delete.svg";
import Email from "../../assets/images/small-email-icon.svg";
import Search from "../../components/Search";
import { toast } from "react-toastify";
import TableViewLoader from "../../components/TableViewLoader";
import Toaster from "../../components/Toaster";
import { useAuthContext } from "../../context/auth";

const FarmUsers = memo(() => {
  const { t } = useTranslation();
  const { setAuthUser } = useAuthContext();
  /* set searchTerm data with useState */
  const [searchTerm, setSearchTerm] = useState("");
  /* set searchResult data with useState */
  const [selectId, setSelectedId] = useState(null);
  const [show, setShow] = useState(false);
  const [user, setUser] = useState(null);
  const [open, setOpen] = useState(false);
  const [display, setDisplay] = useState(false);
  const [farmUsersData, setFarmUsersData] = useState([]);
  const [role, setRole] = useState(null);
  const [tableViewLoader, setTableViewLoader] = useState(false);
  const userRole = getUser();

  const [email, setSelectedEmail] = useState(null);
  const tableStructure = [
    { colName: t("name"), dataKey: "name" },
    { colName: t("email"), dataKey: "email" },
    { colName: t("phone"), dataKey: "phone" },
    { colName: t("role"), dataKey: "role" },
    { colName: t("is_registered"), dataKey: "registered" },
  ];

  const { dairyID } = useParams();

  /** Farm users Listing */
  const getFarmUserListing = () => {
    setTableViewLoader(true);
    fetchFarmUsers(dairyID)
      .then((response) => {
        setTableViewLoader(false);
        if(response.data?.user && response.data?.user.id) {
          const foundUser = response.data?.farmUsers?.find((a => {
            return response.data?.user.id === a.id
          }));
          if (!foundUser) {
            response.data.farmUsers.push(response.data?.user);
          }
        }
        setFarmUsersData(response.data?.farmUsers);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getFarmUserListing();
  }, []);

  const deleteFarmUsers = (id) => {
    const userObj = JSON.parse(localStorage.getItem('user'));
    if(role === 'DairyAdmin') {
      deleteOrUnAssignFarmUser(id, dairyID)
        .then((response) => {
          if (response.status === 200) {
            // when user deleted his own account he must be thrown on login page.
            if(String(userObj.id) === String(id) && response.data.isUserDeleted) {
              localStorage.removeItem("token");
              localStorage.removeItem("user");
              localStorage.removeItem("lastUrl");
              localStorage.setItem("logoutMode", true);
              setAuthUser();
              window.location.href = "/login";
            }
            setSelectedId(null);
            setOpen(false);
            toast.dark(
              <Toaster
                className="text-capitalize"
                icon="notify"
                message={t("dashboard_users_deleted_successfully")}
              />,
              { autoClose: 3000, hideProgressBar: true }
            );
            getFarmUserListing();
          }
        })
        .catch((err) => {
          console.error(err);
          toast.dark(
            <Toaster
              icon="error"
              message={err?.response?.message ?? t("something_went_wrong")}
            />
          );
        });
    }else if (role === "Sanitarian" || role == "MilkHauler") {
      unassignFarmUser(id, dairyID)
        .then((response) => {
          if (response.status === 200) {
            setSelectedId(null);
            setOpen(false);
            toast.dark(
              <Toaster
                className="text-capitalize"
                icon="notify"
                message={t("dashboard_users_deleted_successfully")}
              />,
              { autoClose: 3000, hideProgressBar: true }
            );
            getFarmUserListing();
          }
        })
        .catch((err) => {
          console.error(err);
          toast.dark(
            <Toaster
              icon="error"
              message={err?.response?.message ?? t("something_went_wrong")}
            />
          );
        });
    } else {
      deleteFarmData(id)
        .then((response) => {
          if (response.status === 200) {
            setSelectedId(null);
            setOpen(false);
            toast.dark(
              <Toaster
                className="text-capitalize"
                icon="notify"
                message={t("dashboard_users_deleted_successfully")}
              />,
              { autoClose: 3000, hideProgressBar: true }
            );
            getFarmUserListing();
          }
        })
        .catch((err) => {
          console.error(err);
          toast.dark(
            <Toaster
              icon="error"
              message={err?.response?.message ?? t("something_went_wrong")}
            />
          );
        });
    }
  };
  /** User not register when send user activation link functionality */
  const sendUserActivationLink = (email) => {
    postResendUserActivationLink({ email: email })
      .then((response) => {
        if (response.status === 200) {
          toast.dark(
            <Toaster icon="notify" message={t("send_invitation_link")} />,
            { autoClose: 3000, hideProgressBar: true }
          );
        }
      })
      .catch((err) => {
        console.error(err);
        toast.dark(
          <Toaster
            icon="error"
            message={err?.response?.message ?? t("something_went_wrong")}
          />
        );
      });
  };
  /** Added and update data after update state data */
  const updatedList = (item, action) => {
    if (action === "add") {
      getFarmUserListing();
    }
    if (action === "edit") {
      getFarmUserListing();
    }
  };
  const results = farmUsersData && farmUsersData.length 
  ? farmUsersData.filter((table) => {
      return table?.name?.toLowerCase().includes(searchTerm?.toLowerCase());
    })
  : [];
  return (
    <>
      <Row>
        <Col xs={12} lg={3} md={8} sm={7} className="mb-4">
          <Search
            placeholder={t("search")}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
        </Col>
        <Col
          xs={12}
          lg={9}
          md={4}
          sm={5}
          className="d-inline-flex justify-content-end mb-4"
        >
          <Button
            className="text-capitalize"
            variant="primary"
            onClick={() => setShow(true)}
          >
            {t("add_new_dashboard_users")}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table responsive borderless className="global-table">
            <thead className="border-bottom">
              <tr className="text-dark-gray">
                {tableStructure.map((col, i) => {
                  return (
                    <th className="font-weight-normal" key={i}>
                      {col.colName}
                    </th>
                  );
                })}
              </tr>
            </thead>
            {tableViewLoader ? (
              <TableViewLoader />
            ) : (
                results.length < 1 ? (
                  <tbody>
                    <tr>
                      <td className="py-2 text-center lead" colspan="5">
                        {t("data_not_available")}
                      </td>
                    </tr>
                  </tbody>
                ) : (
                    <tbody>
                      {results.map((data) => {
                        return (
                          <tr key={data.id}>
                            <td style={{ maxWidth: "180px" }} className="text-truncate">
                              {data.name}
                            </td>
                            <td style={{ maxWidth: "180px" }} className="text-truncate">
                              {data.email}
                            </td>
                            <td>{data.contact}</td>
                            <td>
                              {data.role === "MilkHauler"
                                ? t("milk_hauler")
                                : "" || data.role === "DairyAdmin"
                                  ? t("site_owner")
                                  : "" || data.role === "DairyOperator"
                                    ? t("site_operator")
                                    : "" || data.role === "Sanitarian"
                                      ? t("Sanitarian")
                                      : ""}
                            </td>
                            <td style={{ minWidth: "180px", paddingTop: "7px" }}>
                              <div>
                                <span className="pr-2">
                                  {data["isActive"] === undefined
                                    ? "Yes"
                                    : data.isActive === false
                                      ? "No"
                                      : "Yes"}
                                </span>
                                <span>
                                  {data.isActive === false ? (
                                    <Button
                                      variant="outline-warning"
                                      className="text-capitalize"
                                      onClick={() => {
                                        setSelectedEmail(data.email);
                                        sendUserActivationLink(data.email);
                                      }}
                                    >
                                      <Image
                                        src={Email}
                                        className="pr-2"
                                        fluid
                                        style={{
                                          verticalAlign: "initial",
                                        }}
                                      />
                                      {t("resend_link")}
                                    </Button>
                                  ) : (
                                      ""
                                    )}
                                </span>
                              </div>
                            </td>
                            <td className="pt-1 text-capitalize">
                              {(userRole.role === "DairyOperator" && data.role === "DairyAdmin") ? ("") : (
                                <Button
                                  variant="link"
                                  className="table-edit-button text-capitalize text-black"
                                  onClick={() => {
                                    setUser(data);
                                    setDisplay(true);
                                  }}
                                >
                                  <Image src={Edit} />
                                </Button>
                              )}
                            </td>
                            <td className="pt-1 text-danger text-capitalize">
                              {(userRole.role === "DairyOperator" && data.role === "DairyAdmin") ? ("") : (
                                <Button
                                  variant="link"
                                  className="delete-button text-danger text-capitalize"
                                  onClick={() => {
                                    setSelectedId(data.id);
                                    setOpen(true);
                                    setRole(data.role);
                                  }}
                                >
                                  <Image src={Delete} />
                                </Button>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )
              )}
          </Table>
        </Col>
      </Row>



      {
        show && (
          <AddFarmUserForm
            showModel={show}
            closeModel={() => setShow(false)}
            updateList={updatedList}
          />
        )
      }

      {
        display && (
          <EditFarmUserForm
            showModel={display}
            closeModel={() => setDisplay(false)}
            userId={user}
            updateList={updatedList}
            sendUserActivationLink={(email) => sendUserActivationLink(email)}
          />
        )
      }

      <PromptModel
        open={open}
        closeAlert={() => {
          setOpen(false);
        }}
        modelContent={t("delete_record_msg")}
        callback={() => deleteFarmUsers(selectId)}
      />
    </>
  );
});

export default FarmUsers;
