import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Image, Button } from "react-bootstrap";
import addTankIcon from "../../assets/images/white-plus-icon.svg";

const NoTankDataAvailable = memo(({ setIsTankEdit, setShowAddTank }) => {
  const { t } = useTranslation();

  return (
    <div className="mt-lg-5 pt-lg-5 mt-sm-3 pt-sm-3 mb-5 pt-5">
      <h4 className="pt-5 mt-5 mb-4 font-weight-normal">
        {t("no_site_data_available")}
      </h4>
      <Button
        variant="primary"
        type="button"
        size="lg"
        onClick={(e) => {
          e.stopPropagation();
          setIsTankEdit(false);
          setShowAddTank(true);
        }}
      >
        <Image src={addTankIcon} className="mr-2 pb-1" fluid />
        {t("add_device")}
      </Button>
    </div>
  );
});

export default NoTankDataAvailable;
