import React, { memo } from "react";
import { Container, Image, Row, Col } from "react-bootstrap";
import MuellerLogo from "../../assets/images/Mueller_dark_logo.svg";
import backarrow from "../../assets/images/black-md-arrow.svg";

const PrivacyPolicyPageEnglish = memo(() => {
    return(
        <Container>
            
            <div className="mb-5 mt-5  ">
                <Row>
                    <Col
                        xs={{ span: 1, offset: 0 }}
                        sm={{ span: 1, offset: 0 }}
                        md={{ span: 1, offset: 0 }}
                        lg={{ span: 1, offset: 0 }}
                        className="pl-3"
                    ><img src={backarrow} className="mt-2 p-3 text-center back-arrow-hover rounded" style={{cursor: 'pointer'}} onClick={() => {window.history.back()}} /></Col>
                <Col
                    xs={{ span: 8, offset: 1 }}
                    sm={{ span: 10, offset: 0 }}
                    md={{ span: 10, offset: 0 }}
                    lg={{ span: 10, offset: 0 }}
                    className="text-center mr-1">
                        {/* <img  src={MuellerLogo} className="logo-style-privacy-policy-page" /> */}
                </Col>
                </Row>
                <Row>
                <Col
                xs={{ span: 12, offset: 0 }}
                sm={{ span: 12, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
                >
                <div className="mt-5 col-12">
                    <h2>Privacy Policy</h2>
                </div>

                <div>
                <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                    <h3 className="text-primary">This website is managed by Paul Mueller Company.</h3>
                        <p>If you use the Paul Mueller Company website and the products and services that can be found there, then you leave us with data. Moreover, the website leaves a number of browser cookies on your computer. These are used to make the website work better on your device and provide you a more valuable user experience.</p>
                        <p>Below you can read which data Paul Mueller Company collects, why we do that and what we might do with that data. You can also read which cookies we use and why. This statement is about our entire website. </p>
                    <h3 className="text-primary">Cookies</h3>
                        <p>In order for this website to function better and faster and to be able to adapt it to your preferences, certain information concerning your use of this website will be collected. This information can be collected by using what is commonly referred to as 'cookies'. These are small text files that are automatically placed on your computer by the visited web page. You can disable the use of 'cookies' via the settings of your browser. You can remove the ‘cookies’ at your discretion through your browser’s ‘cookie’ management functionality. This, however, leads to a less than optimal user experience on this website. Third parties can also place cookies and collect similar personal data.</p>
                    <h3 className="text-primary">How do we get your data?</h3>
                       <p> If you use the Paul Mueller Company website, you will leave personal data with us in the following ways.</p>
                       <p>Automatically collected data through navigation through the website, such as cookie identifiers, device type, browser type and pages on the site you have visited.<br />
                        Voluntary information provided by you when you send messages via contact forms or activate other internet forms on the website.</p>
                    <h3 className="text-primary">What do we do with your data?</h3>
                        <p>Paul Mueller Company stores and processes your data with the following goals:</p>
                        <ul>
                            <li>Collection and analysis of statistics in order to optimize the website;</li>
                            <li>Delivering the products and services that you purchase from us;</li>
                            <li>Building a contact history;</li>
                            <li>Being able to send mailings and newsletters.</li>
                        </ul>
                    <h3 className="text-primary">How do we secure your data?</h3>
                        <p>To protect your data, we will take appropriate measures that are consistent with applicable data protection and data security laws and regulations, including requiring our service providers to use appropriate measures to protect the confidentiality and security of your data.  Depending on the state of the art, the costs of the implementation and the nature of the data to be protected, we put in place technical and organizational measures to prevent risks such as destruction, loss, alteration, unauthorized disclosure of, or access to your data.</p>
                    <h3 className="text-primary">With whom does Paul Mueller Company share your data?</h3>
                        <p>Paul Mueller Company will treat your data as confidential. We will share your data with our trusted network of Paul Mueller Company manufacturer’s representatives and authorized dealers only when required to provide you with the requested service products or service.</p>
                        <p>We share a limited set of personal data from MilkSecure™ users with milk haulers, the local dairy farm equipment dealer, and inspectors when it is required for pickup, equipment service, or ensuring compliance with governing regulations.</p>
                        <p>We will never sell your data to another party without expressed consent from you.</p>
                    <h3 className="text-primary">Contact, mailing and/or advertising</h3>
                        <p>If you do receive mail or unwanted advertising, you can always indicate that you no longer want us to contact you. There are no costs associated with unsubscribing.</p>
                    <h3 className="text-primary">Younger than 16 years?</h3>
                       <p>If you are a minor, i.e. younger than 16, you may only consent to the processing of your personal data with the consent of one of your parents or a legal guardian. If younger than 16, it is important that your parent(s) or your guardian read this statement. They may also exercise the rights that you have with regard to the personal data you submit, such as the right to oppose the (further) processing of your personal data or the right to access and correct your data.</p>
                    <h3 className="text-primary">What can you do with your data?</h3>
                        <p>We aim to provide you with access to your data. Usually you can autonomously control your data (e.g. by logging in to your account) and update, modify or, if legally possible, delete it.  In this case, we strongly encourage you to take control of your data.</p>
                        <p>You can always contact us if you would like to:</p>
                        <ul>
                            <li>review, change or delete the data you have supplied us with (to the extent Paul Mueller Company is not otherwise permitted or required to keep such data); </li>
                            <li>object to certain data processing operations (e.g., opt-out from marketing communications); </li>
                            <li>receive a copy of your data (in a common machine readable format, to the extent it is required by applicable law); </li>
                            <li>ask us any other questions related to the protection of your data in Paul Mueller Company</li>
                            <li>You can send your requests to <a href="mailto:privacy@paulmueller.com">PRIVACY@PAULMUELLER.COM</a>.</li>
                        </ul>
                       <p> We will do our best to address your request in a timely manner and free of charge, except where it would require a disproportionate effort. In certain cases, we may ask you to verify your identity before we can act on your request.</p>
                    <h3 className="text-primary">Third party websites</h3>
                        <p>This Privacy Statement does not apply to websites of third parties that can be visited via links from this website.</p>
                    <h3 className="text-primary">Changes to this privacy statement</h3>
                        <p>Paul Mueller Company reserves the right to make changes to this Privacy Statement. It is therefore recommended that you review this Privacy Statement regularly so that you are kept informed of these changes. The latest version can always be consulted via the website.</p>
                </div>
            </div>
            </Col>
            </Row>
            </div>
        </Container>
  
    );
});

export default PrivacyPolicyPageEnglish;
