import React, { memo } from "react";
import { Row, Col } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
const ParseRegisterLoader = memo(() => {
  return (<SkeletonTheme color="#f3f3f3" highlightColor="#ecebeb">
    <div>
      <Skeleton count={1} height={70} width={885} />
      <Row className="mt-3">
        <Col lg={6} md={6}>
          <Skeleton count={1} height={300} width={430} />
        </Col>
        <Col lg={6} md={6}>
          <Skeleton count={1} height={300} width={430} />
        </Col>
      </Row>
    </div>
  </SkeletonTheme >
  );
});

export default ParseRegisterLoader;