import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, Form, Modal } from "react-bootstrap";
import CalibrationChartSearch from "components/CalibrationChartSearch/CalibrationChartSearch";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import * as yup from "yup";

const AddTankForm = memo(
  ({ show, close, dairy, addNewTank, edit, tank, editTank, dealerID }) => {
    const { t } = useTranslation();
    const schema = yup.object().shape({
      producerID: yup.string().trim().max(50).matches(/^[a-zA-Z0-9 ]*$/),
      tankName: yup.string().required(),
      noOfEVCs: yup.number().integer().typeError(t("evc_error")),
    });

    const [selectCalibration, setSelectCalibration] = useState(null);
    const [calibrationData, setCalibrationData] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const { register, errors, handleSubmit } = useForm({
      validationSchema: schema,
    });

    /** Select calibration value */
    const handleChange = (value) => {
      if (value.length > 0) {
        setSelectCalibration(value[0].id);
        setCalibrationData(value);
      }
    }

    useEffect(() => {
      if (tank?.calibrationChart) {
        setCalibrationData([{
          id: tank.calibrationChart.id,
          name: tank.calibrationChart.chartName,
        }]);
        setSelectCalibration(tank?.calibrationChart?.id);
      }
      else {
        setCalibrationData([]);
      }
    }, [tank])

    const onSubmit = async (data) => {
      setDisabled(true);
      data.calibrationChart = selectCalibration
      if (edit) {
        await editTank(tank.id, data);
        setDisabled(false);
        setCalibrationData([]);
      } else {
        addNewTank(data);
        setDisabled(false);
      }
    };

    return (
      <Modal
        show={show}
        onHide={close}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header className="bg-primary" closeButton>
            <Modal.Title className="lead text-capitalize text-white mb-0 font-weight-bold">
              {edit ? "Tank" : "Tank"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pb-0">
            <Row>
              <Col lg={12} className="mb-2 pb-1">
                <h3>{t('tank_detail')}</h3>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group controlId="producerID">
                  <Form.Label className="text-capitalize" size="lg">
                    {t("producer_id")}
                  </Form.Label>
                  <Form.Control
                    name="producerID"
                    size="lg"
                    type="text"
                    ref={register()}
                    defaultValue={edit ? tank.producerID : ""}
                    isInvalid={!!errors.producerID}
                  />
                  { errors.producerID &&
                    (<Form.Control.Feedback type="invalid">
                      { errors.producerID?.type === 'matches'
                        ? t('producerId_alphanumeric_error')
                        : errors.producerID?.type === 'max' && t('producerId_max_length_error') }
                    </Form.Control.Feedback>) }
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group controlId="exampleForm.ControlSelect2">
                  <Form.Label className="text-capitalize" size="lg">
                    {t("tank_name")} <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    name="tankName"
                    size="lg"
                    type="text"
                    ref={register()}
                    defaultValue={edit ? tank.tankName : ""}
                    isInvalid={errors.tankName}
                  />
                  {errors.tankName && (
                    <Form.Control.Feedback type="invalid">
                      {t("required_field_error")}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group controlId="exampleForm.ControlSelect2">
                  <Form.Label className="text-capitalize">
                    {t("num_evcs")}
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="noOfEVCs"
                    size="lg"
                    min={0}
                    ref={register()}
                    defaultValue={edit ? tank.noOfEVCs : 0}
                    isInvalid={!!errors.noOfEVCs}
                  />

                  {errors.noOfEVCs && (
                    <Form.Control.Feedback type="invalid">
                      {errors.noOfEVCs.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group controlId="dairyName">
                  <Form.Label className="text-capitalize" size="lg">
                    {t("dairy_name")}
                  </Form.Label>
                  <Form.Control size="lg" type="text" value={dairy} readOnly />
                </Form.Group>
              </Col>

            </Row>
            <Row className="mt-3">
              <Col lg={12} className="mb-2 pb-1">
                <h3>{t('calibration')}</h3>
              </Col>
              <Col lg={12}>
                <Form.Group controlId="exampleForm.ControlSelect2">
                  <Form.Label className="text-capitalize">
                    {t("calibration_chart")}
                  </Form.Label>
                  <CalibrationChartSearch 
                    handleChange={handleChange}
                    dealerID={dealerID}
                    setSelectCalibration={edit ? setSelectCalibration : null}
                    calibrationData={calibrationData}
                    edit={edit ? true : false}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="pt-0">
            <Button
              variant="primary"
              type="submit"
              className="text-capitalize"
              size="lg"
              disabled={disabled}
            >
              {edit ? t("update") : t("create")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
);

AddTankForm.propTypes = {
  show: PropTypes.bool,
  close: PropTypes.func.isRequired,
  dairy: PropTypes.string.isRequired,
  addNewTank: PropTypes.func.isRequired,
  edit: PropTypes.bool,
  tank: PropTypes.any,
  editTank: PropTypes.func.isRequired,
};

AddTankForm.defaultProps = {
  show: false,
  edit: false,
  tank: {},
};

export default AddTankForm;
