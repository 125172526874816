import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useApiContext } from "../../context/apiContext";

import * as yup from "yup";
import {
  updateDairy,
  fetchDairiesByUserId,
  getUser,
} from "../../actions/actions";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Toaster from "../../components/Toaster";

const EditDairyFarmForm = ({
  showModel,
  closeModel,
  dairyId,
  updateList,
  dairyData,
}) => {
  const { t } = useTranslation();
  const apiRegion = useApiContext();
  const { dealerID } = useParams();
  const schema = yup.object().shape({
    name: yup.string().required(t("site_name_error")),
    city: yup.string().required(t("site_city")),
    state: yup.string().required(t("site_state")),
    address: yup.string().required(t("site_address")),
    user: yup.string(),
    county: yup.string(),
  });

  const [user, setUser] = useState(dairyData);
  const { register, handleSubmit, errors, reset } = useForm({
    mode: "onSubmit",
    validationSchema: schema,
  });

  const userRole = getUser();
  useEffect(() => {
    if (user) {
      reset({
        name: user.name,
        city: user.city,
        state: user.state,
        address: user.address,
        user: user?.user?.id,
        county: user?.county
      });
    }
  }, [user]);

  const postDairyFarmData = (data) => {
    if (data.user === "no-dairy-owner") {
      data.user = null;
    }
    updateDairy(dairyId, data)
      .then((result) => {
        if (result.status === 200) {
          closeModel();
          updateList(result.data, "edit");
          toast.dark(
            <Toaster
              className="text-capitalize"
              icon="notify"
              message={t("site_updated_successfully")}
            />,
            { autoClose: 3000, hideProgressBar: true }
          );
        }
      })
      .catch((e) => {
        toast.dark(
          <Toaster
            icon="error"
            message={e?.result?.message ?? t("something_went_wrong")}
          />
        );
      });
  };

  return (
    <Modal
      show={showModel}
      onHide={closeModel}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Form onSubmit={handleSubmit(postDairyFarmData)}>
        <Modal.Header className="bg-primary" closeButton>
          <Modal.Title className="lead text-capitalize text-white mb-0 font-weight-bold">
            {t("site")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <Row>
            <Col lg={6}>
              <Form.Group controlId="formBasicName">
                <Form.Label className="text-capitalize" size="lg">
                  {t("site_name")} <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  name="name"
                  size="lg"
                  type="text"
                  ref={register()}
                  isInvalid={!!errors.name}
                />
                {errors.name && (
                  <Form.Control.Feedback type="invalid">
                    {errors.name.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group controlId="formBasicName">
                <Form.Label className="text-capitalize" size="lg">
                  {t("address")} <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  name="address"
                  size="lg"
                  type="text"
                  ref={register()}
                  isInvalid={!!errors.address}
                />
                {errors.address && (
                  <Form.Control.Feedback type="invalid">
                    {errors.address.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group controlId="formBasicName">
                <Form.Label className="text-capitalize" size="lg">
                  {t("county")}
                </Form.Label>
                <Form.Control
                  name="county"
                  size="lg"
                  type="text"
                  ref={register()}
                  isInvalid={!!errors.county}
                />
                {errors.county && (
                  <Form.Control.Feedback type="invalid">
                    {errors.county.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group controlId="formBasicName">
                <Form.Label className="text-capitalize" size="lg">
                  {t("city")} <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  name="city"
                  size="lg"
                  type="text"
                  ref={register()}
                  isInvalid={!!errors.city}
                />
                {errors.city && (
                  <Form.Control.Feedback type="invalid">
                    {errors.city.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group controlId="formBasicName">
                <Form.Label className="text-capitalize" size="lg">
                  {t("state")} <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  name="state"
                  size="lg"
                  type="text"
                  ref={register()}
                  isInvalid={!!errors.city}
                />
                {errors.state && (
                  <Form.Control.Feedback type="invalid">
                    {errors.state.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="pt-0">
          <Button
            variant="primary"
            type="submit"
            className="text-capitalize"
            size="lg"
          >
            {t("update")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

EditDairyFarmForm.propTypes = {
  showModel: PropTypes.bool,
  closeModel: PropTypes.func.isRequired,
  dairyId: PropTypes.string.isRequired,
};

EditDairyFarmForm.defaultProps = {
  showModel: false,
};

export default EditDairyFarmForm;
