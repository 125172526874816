import React from "react";
import LoginPage from "./pages/LoginPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import Error404Page from "./pages/404Page";
import TankPage from "./pages/TankPage";
import ProfilePage from "./pages/ProfilePage";
import RegionPage from "./pages/RegionPage";
import DealersPage from "./pages/DealersPage";
import DairiesPage from "./pages/DairiesPage";
import ChangedPasswordPage from "./pages/ChangedPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import RedirectDairies from "./pages/RedirectDairiesPages";
import ContactAdministrator from "./pages/ContactAdministrator";
import BreadcrumbData from "./containers/Breadcrumb/BreadcrumbData";
import PrivacyPolicyPageEnglish from "./pages/PrivacyPolicyPages/english";
import PrivacyPolicyPageSpanish from "./pages/PrivacyPolicyPages/spanish";
import PrivacyPolicyPageFrenchCanadian from "./pages/PrivacyPolicyPages/canadianFrench";
import PrivacyPolicyPageDutch from "./pages/PrivacyPolicyPages/dutch";
import PrivacyPolicyPageGerman from "./pages/PrivacyPolicyPages/german";
import PrivacyPolicyPageJapanese from "./pages/PrivacyPolicyPages/japanese";

const routes = {
  publicRoutes: [
    {
      path: "/login",
      exact: true,
      component: LoginPage,
    },
    {
      path: "/forgot-password",
      exact: true,
      component: ForgotPasswordPage,
    },
    {
      path: "/reset-password",
      exact: true,
      component: ResetPasswordPage,
    },
    {
      path: "/page-not-found",
      exact: true,
      component: Error404Page,
    },
    {
      path: "/contact-administrator",
      exact: true,
      component: ContactAdministrator,
    },
    {
      path: "/en/privacy-policy",
      exact: true,
      component: PrivacyPolicyPageEnglish,
    },
    {
      path: "/es/privacy-policy",
      exact: true,
      component: PrivacyPolicyPageSpanish,
    },
    {
      path: "/frca/privacy-policy",
      exact: true,
      component: PrivacyPolicyPageFrenchCanadian,
    },
    {
      path: "/nl/privacy-policy",
      exact: true,
      component: PrivacyPolicyPageDutch,
    },
    {
      path: "/de/privacy-policy",
      exact: true,
      component: PrivacyPolicyPageGerman,
    },
    {
      path: "/jp/privacy-policy",
      exact: true,
      component: PrivacyPolicyPageJapanese,
    },
  ],
  privateRoutes: [
    {
      path: "/",
      // No user role should be able to access this route, when they try they should be re-directed to their homepage
      ableToAccess: [],
      exact: true,
      component: RegionPage,
    },
    {
      path: "/profile",
      ableToAccess: [
        "SuperAdmin",
        "RegionalManager",
        "Dealer",
        "Technician",
        "DairyAdmin",
        "DairyOperator",
        "Sanitarian",
        "MilkHauler",
      ],
      exact: true,
      component: ProfilePage,
    },
    {
      path: "/change-password",
      ableToAccess: [
        "SuperAdmin",
        "RegionalManager",
        "Dealer",
        "Technician",
        "DairyAdmin",
        "DairyOperator",
        "Sanitarian",
        "MilkHauler",
      ],
      exact: true,
      component: ChangedPasswordPage,
    },
    {
      path: "/regions",
      // Only SuperAdmins should be able to see the list of regions
      ableToAccess: ["SuperAdmin"],
      exact: true,
      breadcrumb: "regions",
      component: RegionPage,
    },
    //This path is needed as we pass Region name on this breadcrumb
    {
      path: "/regions/:regionID",
      ableToAccess: ["SuperAdmin", "RegionalManager"],
      exact: true,
      breadcrumb: (props) => <BreadcrumbData {...props} type="regions" />,
      to: "/dealers",
    },
    {
      path: "/regions/:regionID/dealers",
      // SuperAdmins and RegionalManagers should be able to see the list of dealers in a given region
      ableToAccess: ["SuperAdmin", "RegionalManager"],
      exact: true,
      component: DealersPage,
      breadcrumb: "dealers",
    },
    //This path is needed as we pass dealer name on this path's breadcrumb
    {
      path: "/regions/:regionID/dealers/:dealerID",
      // SuperAdmins and RegionalManagers should be able to see the list of dealers in a given region
      ableToAccess: ["SuperAdmin", "RegionalManager", "Dealer"],
      exact: true,
      to: "/dairies",
      breadcrumb: (props) => <BreadcrumbData {...props} type="dealers" />,
      // component: DairiesPage,
    },

    {
      path: "/regions/:regionID/dealers/:dealerID/dairies",
      // SuperAdmins, RegionalManagers and Dealers should be able to see the list of dairies in a given dealer
      // TODO: Technician, Sanitarian and MilkHauler should be able to see the list of dairies assigned to them
      ableToAccess: ["SuperAdmin", "RegionalManager", "Dealer"],
      exact: true,
      component: DairiesPage,
      breadcrumb: (props) => <BreadcrumbData {...props} type="dairyFarm" />,
    },
    {
      path:
        "/regions/:regionID/dealers/:dealerID/dairies/:dairyID/tank/:tankID",
      // SuperAdmins, RegionalManagers and Dealers should be able to see the list of dairies in a given dealer
      // TODO: Technician, Sanitarian and MilkHauler should be able to see the list of dairies assigned to them
      ableToAccess: [
        "SuperAdmin",
        "RegionalManager",
        "Dealer",
        "DairyAdmin",
        "DairyOperator",
        "Sanitarian",
        "MilkHauler",
      ],
      exact: true,
      component: TankPage,
    },
    {
      path: "/regions/:regionID/dealers/:dealerID/dairies/:dairyID/tank",
      // SuperAdmins, RegionalManagers and Dealers should be able to see the list of dairies in a given dealer
      // TODO: Technician, Sanitarian and MilkHauler should be able to see the list of dairies assigned to them
      ableToAccess: [
        "SuperAdmin",
        "RegionalManager",
        "Dealer",
        "Technician",
        "DairyAdmin",
        "Sanitarian",
        "MilkHauler",
      ],
      exact: true,
      component: TankPage,
      active: true,
      breadcrumb: (props) => <BreadcrumbData {...props} type="dairyFarm" />,
    },
    {
      path: "/dairies",
      // Technician, DairyAdmin, DairyOperator, Sanitarian and MilkHauler should be able to see the list of dairies assigned to them
      ableToAccess: ["DairyAdmin", "DairyOperator", "Sanitarian", "MilkHauler"],
      exact: true,
      component: DairiesPage,
      breadcrumb: (props) => <BreadcrumbData {...props} type="dairyFarm" />,
    },
    // Independent Dairies Path
    {
      path: "/regions/dairies/:dairyID",
      // Every user roles should be able to access the tanks
      ableToAccess: [
        "SuperAdmin",
        "RegionalManager",
        "Technician",
        "DairyAdmin",
        "DairyOperator",
        "Sanitarian",
        "MilkHauler",
      ],
      exact: true,
      component: RedirectDairies,
      breadcrumb: (props) => <BreadcrumbData {...props} type="dairyFarm" />,
    },
    {
      path: "/dairies/:dairyID/tank/:tankID",
      // Technician, DairyAdmin, DairyOperator, Sanitarian and MilkHauler should be able to see the list of dairies assigned to them
      ableToAccess: ["DairyAdmin", "DairyOperator", "Sanitarian", "MilkHauler"],
      exact: true,
      component: TankPage,
      breadcrumb: (props) => <BreadcrumbData {...props} type="dairyFarm" />,
    },
    {
      path: "/dairies/:dairyID/tank",
      ableToAccess: [
        "SuperAdmin",
        "Technician",
        "DairyAdmin",
        "DairyOperator",
        "Sanitarian",
        "MilkHauler",
      ],
      exact: true,
      component: TankPage,
    },
    //Independent Dairies Tank page path
    {
      path: "/regions/dairies/:dairyID/tank",
      ableToAccess: [
        "SuperAdmin",
        "Technician",
        "DairyAdmin",
        "DairyOperator",
        "Sanitarian",
        "MilkHauler",
      ],
      exact: true,
      component: TankPage,
    },
    //Independent Dairies Tank page path with a selected tank
    {
      path: "/regions/dairies/:dairyID/tank/:tankID",
      ableToAccess: [
        "SuperAdmin",
        "Technician",
        "DairyAdmin",
        "DairyOperator",
        "Sanitarian",
        "MilkHauler",
      ],
      exact: true,
      component: TankPage,
    },
    //Independent Dealers with dealer Id route
    {
      path: "/regions/dealers/:dealerID",
      // Every user roles should be able to access the tanks
      ableToAccess: [
        "SuperAdmin",
        "Dealer",
        "Technician",
        "DairyAdmin",
        "DairyOperator",
        "Sanitarian",
        "MilkHauler",
      ],
      exact: true,
      breadcrumb: (props) => <BreadcrumbData {...props} type="dealers" />,
      to: "/dairies",
    },
    //Independent Dealers dairies path
    {
      path: "/regions/dealers/:dealerID/dairies",
      // Every user roles should be able to access the tanks
      ableToAccess: [
        "SuperAdmin",
        "Dealer",
        "Technician",
        "DairyAdmin",
        "DairyOperator",
        "Sanitarian",
        "MilkHauler",
      ],
      exact: true,
      component: DairiesPage,
      breadcrumb: (props) => <BreadcrumbData {...props} type="dairyFarm" />,
    },
    //Independent Dealers dairy tanks path
    {
      path: "/regions/dealers/:dealerID/dairies/:dairyID/tank",
      ableToAccess: [
        "SuperAdmin",
        "Dealer",
        "Technician",
        "DairyAdmin",
        "DairyOperator",
        "Sanitarian",
        "MilkHauler",
      ],
      exact: true,
      component: TankPage,
      breadcrumb: (props) => <BreadcrumbData {...props} type="dairyFarm" />,
    },
    //Independent Dealers dairy tanks path with a selected tank
    {
      path: "/regions/dealers/:dealerID/dairies/:dairyID/tank/:tankID",
      ableToAccess: [
        "SuperAdmin",
        "Dealer",
        "Technician",
        "DairyAdmin",
        "DairyOperator",
        "Sanitarian",
        "MilkHauler",
      ],
      exact: true,
      component: TankPage,
    },
  ],
};

export default routes;
