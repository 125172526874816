import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useHistory, Link } from "react-router-dom";
import {
  Col,
  Row,
  Container,
  Form,
  Button,
  Image,
  Spinner,
} from "react-bootstrap";
import * as yup from "yup";
import jwt_decode from "jwt-decode";
import MuellerLogo from "../assets/images/Mueller_dark_logo.svg";
import MilkSecureLogo from "../assets/images/PMC_LOGO.svg";
import { useAuthContext } from "../context/auth";
import { resetPassword } from "../actions/actions";
import { rootApi } from "../axiosConfig";
import { PASSWORD_REGEXP } from "../actions/passwordRegex";
import { toast } from "react-toastify";
import Toaster from "../components/Toaster";
import EyeIcon from "../assets/images/BlueEyesIcon.svg";
import CloseEyeIcon from "../assets/images/close-eye-icon.svg";
import Slider from "../components/Slider";

import PrivacyPolicyLinks from "../components/PrivacyPolicyLinks";
import LanguageDropdown from "../components/LanguageDropdown";

const ResetPasswordPage = memo(() => {
  const [spinner, setSpinner] = useState(false);
  const { authUser, setAuthUser } = useAuthContext();
  const [isPassword, setShowPassword] = useState(false);
  const [isConfirmPassword, setConfirmPassword] = useState(false);

  const { t } = useTranslation();
  const schema = yup.object().shape({
    newpassword: yup
      .string()
      .required(t("password_required"))
      .min(8, t("password_min"))
      .matches(PASSWORD_REGEXP, t("password_regex_message")),
    confirmpassword: yup
      .string()
      .required(t("confirm_password_required"))
      .min(8, t("password_min"))
      .oneOf([yup.ref("newpassword")], t("password_not_match")),
  });

  const { register, handleSubmit, errors, reset } = useForm({
    validationSchema: schema,
  });
  /** onClick button show and hide eye icon */
  const handleClick = () => {
    setShowPassword(!isPassword);
  };

  const [errorMessage, setErrorMessge] = useState();
  const history = useHistory();
  const temp = window.location.search.substring(1);
  const token = temp.replace("token=", "");
  const [userEmail, setUserEmail] = useState("");
  const [active, setActive] = useState(false);
  useEffect(() => {
    if (token) {
      rootApi
        .get(`/tokenverifier`, {
          headers: {
            token: token,
          },
        })
        .then((result) => {
          if (result.status === 200) {
            setUserEmail(result.data.email);
          }
        })
        .catch((e) => {
          if (e.response.status === 403)
            setActive(true)
          else {
            (
              toast.dark(
                <Toaster
                  icon="error"
                  message={e?.result?.message ?? t("something_went_wrong")}
                />
              )
            )
          }
        });
    }
  }, []);
  const putResetPassword = (data) => {
    resetPassword({ password: data.newpassword, token: token })
      .then((result) => {
        setSpinner(false);
        if (result.status === 200) {
          setAuthUser(result.data.user, result.data.token);
          toast.dark(
            <Toaster
              className="text-capitalize"
              icon="notify"
              message={t("changed_password_message")}
            />,
            { autoClose: 3000, hideProgressBar: true }
          );
          history.push("/");
        }
      })
      .catch((err) => {
        setSpinner(false);
        setErrorMessge(
          err && err.response
            ? err.response.data.message
            : t("something_went_wrong")
        );
      });
  };

  return (
    <>
      <div className="bg-login-light min-vh-100">
        <Container fluid="xl" fluid className="min-vh-100">
          <Row className="min-vh-100 bg-dark-secondary">
            <Col
              lg={6}
              md={12}
              sm={12}
              xs={12}
              xl={6}
              className="d-none d-sm-none d-md-none bg-dark-secondary align-items-start d-lg-flex justify-content-lg-start justify-content-xl-center"
            >
              <Slider />
            </Col>
            <Col
              className="m-xl-auto mx-lg-auto"
              xs={{ span: 10, offset: 1 }}
              sm={{ span: 8, offset: 2 }}
              md={{ span: 8, offset: 2 }}
              lg={{ span: 4, offset: 2 }}
              xl={{ span: 4, offset: 2 }}
            >
              <div className="text-center my-lg-4 my-md-5 logo-top">
                {/* <Image src={MuellerLogo} fluid className="mueller-logo" /> */}
              </div>

              {active ? (
                <div className="bg-white px-5 py-1 mt-4 mt-5">
                  <div className="lead text-center mt-4">
                    {t("resend_email_link_expired")}
                  </div>

                  <Link to="/">
                    <Button
                      className="mt-4"
                      variant="primary"
                      size="lg"
                      type="submit"
                      block
                    >
                      {t("back_to_login")}
                    </Button>
                  </Link>
                  <div className="px-2 text-center mt-3">
                    <h5 className="px-3 py-2 mb-4 text-dark-gray font-weight-normal text-center sign-up-text">
                      {t("by_signing_up")}
                    </h5>
                  </div>
                </div>
              ) : (
                  <div className="shadow bg-white px-lg-5 px-md-5 px-4 py-1 mt-3">
                    <div className="lead text-center text-capitalize mt-4">
                      {t("set_password")}
                    </div>
                    <h5 className="p-2 mb-3 text-dark-gray font-weight-normal text-center sign-up-text">
                      {t("set_validation_message")}
                    </h5>
                    <Form className="" onSubmit={handleSubmit(putResetPassword)}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Control
                          name="email"
                          size="lg"
                          type="email"
                          className="border-0 shadow-none"
                          placeholder={t("enter_email")}
                          ref={register()}
                          isInvalid={!!errors.email}
                          value={userEmail}
                          disabled
                        />
                        {errors.email && (
                          <Form.Control.Feedback type="invalid">
                            {t("required_field_error")}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                      <Form.Group controlId="formBasicPassword">
                        <Form.Control
                          name="newpassword"
                          size="lg"
                          type={isPassword === true ? "text" : "password"}
                          placeholder={t("enter_a_new_password")}
                          ref={register()}
                          isInvalid={!!errors.newpassword}
                        />
                        <Button
                          variant="link"
                          className="eye-icon"
                          aria-label="Eye Icon"
                          onClick={handleClick}
                        >
                          {isPassword === false ? (
                            <Image src={EyeIcon} />
                          ) : (
                              <Image src={CloseEyeIcon} />
                            )}
                        </Button>
                        {errors.newpassword && (
                          <Form.Control.Feedback type="invalid">
                            {errors.newpassword.message}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>

                      <Form.Group controlId="formBasicPassword">
                        <Form.Control
                          name="confirmpassword"
                          size="lg"
                          type={isConfirmPassword === true ? "text" : "password"}
                          placeholder={t("confirm_new_password")}
                          ref={register()}
                          isInvalid={!!errors.confirmpassword}
                        />
                        <Button
                          variant="link"
                          className="eye-icon"
                          aria-label="Eye Icon"
                          onClick={() => setConfirmPassword(!isConfirmPassword)}
                        >
                          {isConfirmPassword === false ? (
                            <Image src={EyeIcon} />
                          ) : (
                              <Image src={CloseEyeIcon} />
                            )}
                        </Button>
                        {errors.confirmpassword && (
                          <Form.Control.Feedback type="invalid">
                            {errors.confirmpassword.message}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>

                      <Button
                        className="mt-4 text-capitalize"
                        variant="primary"
                        size="lg"
                        type="submit"
                        block
                      >
                        {spinner ? (
                          <>
                            <Spinner
                              as="span"
                              animation="border"
                              size="md"
                              role="status"
                              aria-hidden="true"
                            />
                            <span className="sr-only">Loading...</span>
                          </>
                        ) : (
                            t("set_password")
                          )}
                      </Button>
                      <div className="px-md-2 px-sm-2  text-center mt-3">
                        <h5 className="mb-4 text-dark-gray font-weight-normal text-center">
                          {t("by_signing_up")}
                        </h5>
                      </div>
                    </Form>
                  </div>
                )}
              {errorMessage && (
                <div className="text-danger mb-2 text-center">
                  {errorMessage}
                </div>
              )}
            <div className="text-center">
                <Image src={MilkSecureLogo} className="mueller-logo"/>
              </div>
              <div className="text-center">
                <PrivacyPolicyLinks />
              </div>
              <div className="text-center">
                <LanguageDropdown />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
});

export default ResetPasswordPage;
