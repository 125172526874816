import React, { memo } from "react";
import { Container, Image, Row, Col } from "react-bootstrap";
import MuellerLogo from "../../assets/images/Mueller_dark_logo.svg";
import backarrow from "../../assets/images/black-md-arrow.svg";

const PrivacyPolicyPageFrenchCanadian = memo(() => {
    return(
        <Container>
            
            <div className="mb-5 mt-5  ">
                <Row>
                    <Col
                        xs={{ span: 1, offset: 0 }}
                        sm={{ span: 1, offset: 0 }}
                        md={{ span: 1, offset: 0 }}
                        lg={{ span: 1, offset: 0 }}
                        className="pl-3"
                    ><img src={backarrow} className="mt-2 p-3 text-center back-arrow-hover rounded" style={{cursor: 'pointer'}} onClick={() => {window.history.back()}} /></Col>
                <Col
                    xs={{ span: 8, offset: 1 }}
                    sm={{ span: 10, offset: 0 }}
                    md={{ span: 10, offset: 0 }}
                    lg={{ span: 10, offset: 0 }}
                    className="text-center mr-1">
                        {/* <img  src={MuellerLogo} className="logo-style-privacy-policy-page" /> */}
                </Col>
                </Row>
                <Row>
                <Col
                xs={{ span: 12, offset: 0 }}
                sm={{ span: 12, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
                >
                <div className="mt-5 col-12">
                    <h2>Politique de confidentialité</h2>
                </div>

                <div>
                <div className="col-lg-12 col-md-12 col-sm-12 mt-3" >
                    <h3 className="text-primary">Ce site Web est géré par Paul Mueller Company.</h3>
                        <p>Lorsque vous utilisez le site Web de Paul Mueller Company, de même que les produits et services qui s’y trouvent, nous recueillons des renseignements sur vous. De plus, le site Web place des témoins de connexion sur votre ordinateur. Ceux-ci sont utilisés afin de permettre un meilleur fonctionnement du site Web sur votre appareil, et de vous offrir une meilleure expérience utilisateur.</p>
                        <p>Vous trouverez ci-dessous la description des renseignements recueillis par Paul Mueller Company, les raisons pour lesquelles nous recueillons ces renseignements, et l’utilisation que nous pourrions en faire. Vous trouverez également une description des témoins que nous utilisons, et à quelles fins. Cette déclaration porte sur notre site Web en entier.</p>
                    <h3 className="text-primary">Témoins de connexion (« cookies »)</h3>
                        <p>Afin d’assurer un meilleur fonctionnement et un affichage plus rapide de ce site Web, de même que pour être en mesure de l’adapter selon vos préférences, certains renseignements relatifs à votre utilisation de ce site Web seront recueillis. Ces renseignements seront recueillis au moyen de témoins de connexion, communément appelés « cookies ». Il s’agit de petits fichier texte qui sont placés automatiquement sur votre ordinateur par les pages Web que vous visitez. Vous pouvez désactiver l’utilisation des témoins de connexion au moyen des paramètres de votre navigateur. Si vous le souhaitez, vous pouvez effacer en tout temps les témoins de connexion grâce aux options de gestion des témoins de connexion de votre navigateur. Toutefois, il en résultera une expérience utilisateur sous-optimale sur ce site Web. Des tiers peuvent également placer des témoins de connexion et recueillir des renseignements personnels de nature semblable.</p>
                    <h3 className="text-primary">Comment obtenons-nous vos renseignements?</h3>
                       <p> Si vous utilisez le site Web de Paul Mueller Company, vous générerez des renseignements personnels des façons suivantes.</p>
                       <p>Renseignements recueillis automatiquement lors de la navigation sur le site Web, par exemple : témoins de connexion, type d’appareil, type de navigateur, ainsi que les pages du site que vous avez visitées.<br />
                       Informations fournies volontairement par vous lorsque vous envoyez des messages par l’entremise de formulaires de contact, ou lorsque vous activez d’autres formulaires Internet sur le site Web.</p>
                    <h3 className="text-primary">Que faisons-nous avec vos renseignements?</h3>
                        <p>Paul Mueller Company conserve et traite vos renseignements aux fins suivantes :</p>
                        <ul>
                            <li>recueillir et analyser des statistiques afin d’optimiser le site Web;</li>
                            <li>livrer les produits et services que vous achetez de nous;</li>
                            <li>établir un historique de contacts;</li>
                            <li>vous envoyer du courrier et des bulletins d’information.</li>
                        </ul>
                    <h3 className="text-primary">Comment sécurisons-nous vos renseignements?</h3>
                        <p>Afin de protéger vos renseignements, nous prendrons des mesures appropriées, conformément à la législation et à la règlementation en vigueur en matière de protection des renseignements et de sécurité des renseignements, notamment en exigeant de nos fournisseurs de service qu’ils prennent les mesures appropriées afin de préserver la confidentialité et la sécurité de vos renseignements.  En fonction des technologies disponibles, des coûts de mise en œuvre et de la nature des renseignements à protéger, nous mettrons en place des mesures techniques et organisationnelles afin de prévenir des risques tels que la destruction, l’altération et la divulgation sans autorisation de vos renseignements, de même que l’accès sans autorisation à ceux-ci.</p>
                    <h3 className="text-primary">Avec qui Paul Mueller Company partage-t-elle vos renseignements?</h3>
                        <p>Paul Mueller Company traitera confidentiellement vos données. Nous partagerons vos données avec notre réseau de confiance de représentants et de concessionnaires autorisés du fabricant Paul Mueller Company uniquement lorsque requis pour vous fournir des produits et services demandés.</p>
                        <p>Nous partageons un ensemble restreint de renseignements personnels des utilisateurs de MilkSecure™ concernant les camions-citernes de réception du lait, le concessionnaire local de la machinerie agricole laitière et les inspecteurs lorsque nécessaire pour le ramassage, pour la réparation de l'équipement ou pour veiller au respect des règlements établis.
                        </p>
                        <p>Nous ne vendrons jamais vos données à un tiers sans votre consentement explicite.
                        </p>
                    <h3 className="text-primary">Communication, envoi de courrier et/ou de publicité</h3>
                        <p>Si vous recevez de la publicité ou du courrier non désirés, vous pouvez nous aviser en tout temps que vous ne voulez plus que nous communiquions avec vous. Vous pouvez vous désabonner sans aucuns frais.</p>
                    <h3 className="text-primary">Vous avez moins de 16 ans?</h3>
                       <p>Si vous êtes mineur, c’est à dire que vous êtes agé(e) de moins de 16 ans, vous ne pouvez consentir au traitement de vos renseignements personnels qu’avec le consentement de l’un de vos parents ou de votre tuteur légal. Si vous avez moins de 16 ans, il est important que vos parents ou votre tuteur légal lisent cette déclaration. Ceux-ci pourraient exercer les droits que vous avez relativement aux renseignements personnels que vous fournissez, par exemple le droit de s’opposer au traitement (futur) de vos renseignements personnels, ou le droit d’accéder à vos renseignements et de les corriger.</p>
                    <h3 className="text-primary">Que pouvez-vous faire avec vos renseignements?</h3>
                        <p>Nous visons à vous donner accès à vos renseignements. Habituellement, vous pouvez contrôler vos renseignements de façon autonome (par exemple, en ouvrant une session sur votre compte) afin de les mettre à jour, de les modifier, ou, si la loi le permet, de les supprimer.  Dans ce cas, nous vous encourageons fortement à prendre le contrôle de vos renseignements.</p>
                        <p>Vous pouvez toujours nous joindre si vous souhaitez :</p>
                        <ul>
                            <li>réviser, modifier ou supprimer les renseignements que vous nous avez fournis (dans la mesure ou Paul Mueller Company n’est pas autrement autorisée à, ou obligée de, conserver ces renseignements); </li>
                            <li>vous objecter à certaines opérations de traitement de vos renseignements (par exemple, si vous ne voulez plus recevoir de messages de marketing); </li>
                            <li>recevoir une copie de vos renseignements (dans un format de fichier courant et lisible par ordinateur, dans la mesure exigée par la législation en vigueur); </li>
                            <li>nous poser toute autre question relative à la protection de vos renseignements au sein de Paul Mueller Company</li>
                            <li>Vous pouvez envoyer vos demandes à <a href="mailto:privacy@paulmueller.com">PRIVACY@PAULMUELLER.COM</a>.</li>
                        </ul>
                       <p> Nous ferons de notre mieux afin de répondre à votre demande gratuitement et dans un délai raisonnable, sauf si l’effort exigé est disproportionné. Dans certains cas, nous pourrions vous demander de prouver votre identité avant de répondre à votre demande.</p>
                    <h3 className="text-primary">Sites Web de tiers</h3>
                        <p>Cette déclaration de confidentialité ne s’applique pas aux sites Web de tiers pouvant être visités à partir de liens situés sur ce site Web.</p>
                    <h3 className="text-primary">Modification de cette déclaration de confidentialité</h3>
                        <p>Paul Mueller Company se réserve le droit d’apporter des changements à cette déclaration de confidentialité. Par conséquent, nous vous recommandons de relire régulièrement cette déclaration de confidentialité afin de demeurer informé de ces changements. La version la plus récente peut être consultée en tout temps sur ce site Web.</p>
                </div>
            </div>
            </Col>
            </Row>
            </div>
        </Container>
  
    );
});

export default PrivacyPolicyPageFrenchCanadian;
