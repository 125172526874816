import React from 'react';
import { Helmet } from 'react-helmet';

const MetaTags = () => {
  return (
	  <>
			<Helmet>
				<meta charset="utf-8" />
				<link rel="icon" href="%PUBLIC_URL%/favicon.png" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<meta name="theme-color" content="#000000" />
				<meta
					name="description"
					content="Access the MilkSecure™ dashboard to receive real-time updates through your internet-connected smartphone, tablet, or computer. Keep an eye on your farm when you need to be away."
				/>
				<meta property="og:type" content="website" />
				<meta property="og:url" content="https://secure.paulmueller.com/" />
				<meta property="og:title" content="MilkSecure™" />
				<meta
					property="og:description"
					content="Access the MilkSecure™ dashboard to receive real-time updates through your internet-connected smartphone, tablet, or computer. Keep an eye on your farm when you need to be away."
				/>
				<link rel="apple-touch-icon" href="%PUBLIC_URL%/favicon.png" />
			</Helmet>
		</>
	);
};

export default MetaTags;
