import React, { memo ,useState , useEffect } from "react";
import { Row, Col, Tabs, Tab, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../components/Breadcrumb";
import RegionTab from "../containers/Region/RegionTab";
import RegionalManagersTab from "../containers/Region/RegionalManagersTab";
import IndependentDealersTab from "../containers/Region/IndependentDealersTab";
import admin from "../assets/images/user.svg";
import SuperAdminTab from "../containers/Region/SuperAdminTab";
import IndependentDairiesTab from "../containers/Region/IndependentDairiesTab";
import { useHistory } from 'react-router-dom';
import queryString from "query-string";

const RegionPage = memo(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const [tabIndex, setTabIndex] = useState("regions");
  const [dealers, setDealers] = useState([]);
  const [independentDairy, setIndependentDairy] = useState([]);

 const unlisten =  history.listen((loction, action) => {
   const data = queryString.parse(history.location.search)
    if (data.independentDairy) {
      setTabIndex("independentDairies")
    }
    else if (data.independentDealers) {
      setTabIndex("independentDealers");
    } else {
      setTabIndex("regions");
    }
  })
  useEffect(() => {
    const data = queryString.parse(history.location.search)
    if (data.independentDairy) setTabIndex("independentDairies");
    else if (data.independentDealers) setTabIndex("independentDealers")
    return ()=>unlisten()
  }, [history])
 
  return (
    <>
      <Container>
        <Row className="mt-4 mb-3">
          <Col lg={12} className="pl-0">
            <Breadcrumb />
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Tabs
              activeKey={tabIndex}
              className="main-tab text-capitalize mb-4"
              onSelect={(event) => {
                history.push("/regions");
                setTabIndex(event);
              }}
            >
              <Tab eventKey="regions" title={t("regions")}>
                <RegionTab setDealers={setDealers} />
              </Tab>
              <Tab
                className="border-0"
                eventKey="regionalManagers"
                title={t("regional_managers")}
              >
                <RegionalManagersTab />
              </Tab>
              <Tab eventKey="superAdmin" title={t("super_admin")}>
                <SuperAdminTab />
              </Tab>
              <Tab
                eventKey="independentDealers"
                title={t("independent_dealers")}
              >
                <IndependentDealersTab
                  dealers={dealers}
                  setDealers={setDealers}
                  setIndependentDairy={setIndependentDairy}
                />
              </Tab>
              <Tab
                eventKey="independentSites"
                title={t("independent_sites")}
              >
                <IndependentDairiesTab
                  setIndependentDairy={setIndependentDairy}
                  independentDairy={independentDairy}
                />
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </>
  );
});

export default RegionPage;
