import React, { memo, useState, useEffect } from "react";
import { Row, Col, Button, Table, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  getTechnician,
  deleteSuperAdmin,
  postResendUserActivationLink,
} from "../../actions/actions";
import Edit from "../../assets/images/edit.svg";
import Delete from "../../assets/images/delete.svg";
import Email from "../../assets/images/small-email-icon.svg";
import Search from "../../components/Search";
import AddTechnicianForm from "../../forms/TechniciansForm/AddTechnicianForm";
import PromptModel from "components/PromptModel/PromptModel";
import EditTechnicianForm from "../../forms/TechniciansForm/EditTechnicianForm";
import TableViewLoader from "../../components/TableViewLoader";
import { toast } from "react-toastify";
import Toaster from "../../components/Toaster";

const TechniciansTab = memo(({ activeTab }) => {
  const { t } = useTranslation();

  const { dealerID } = useParams();
  /* set searchTerm data with useState */
  const [searchTerm, setSearchTerm] = useState("");
  /* set searchResult data with useState */
  const [searchResults, setSearchResults] = useState([]);
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectId, setSelectedId] = useState(null);
  const [display, setDisplay] = useState(false);
  const [user, setUser] = useState([]);
  const [techData, setTechData] = useState([]);
  const [email, setSelectedEmail] = useState(null);
  const [tableViewLoader, setTableViewLoader] = useState(false);

  const tableStructure = [
    { colName: t("name"), dataKey: "name" },
    { colName: t("email"), dataKey: "email" },
    { colName: t("phone"), dataKey: "phone" },
    { colName: t("sites"), dataKey: "dairiesToMonitor" },
    { colName: t("is_registered"), dataKey: "registered" },
  ];
  const displayTechnicians = () => {
    setTableViewLoader(true);
    getTechnician(dealerID)
      .then((response) => {
        setTableViewLoader(false);
        setSearchResults(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if(activeTab === "technicians") {
      displayTechnicians();
    }
  }, [dealerID, activeTab]);

  const updatedList = (item, action) => {
    if (action === "add") {
      setSearchResults([item, ...searchResults]);
    }
    if (action === "edit") {
      setSearchResults(searchResults.map((i) => (i.id === item.id ? item : i)));
    }
  };

  const deleteTechnicians = (id) => {
    deleteSuperAdmin(id)
      .then((response) => {
        if (response.status === 200) {
          setSelectedId(null);
          setOpen(false);
          toast.dark(
            <Toaster
              className="text-capitalize"
              icon="notify"
              message={t("technician_deleted_successfully")}
            />,
            { autoClose: 3000, hideProgressBar: true }
          );
          displayTechnicians();
        }
      })
      .catch((err) => {
        console.error(err);
        toast.dark(
          <Toaster
            icon="error"
            message={err?.response?.message ?? t("something_went_wrong")}
          />
        );
      });
  };

  /** User not register when send user activation link functionality */
  const sendUserActivationLink = (email) => {
    postResendUserActivationLink({ email: email })
      .then((response) => {
        if (response.status === 200) {
          toast.dark(
            <Toaster icon="notify" message={t("send_invitation_link")} />,
            { autoClose: 3000, hideProgressBar: true }
          );
        }
      })
      .catch((err) => {
        console.error(err);
        toast.dark(
          <Toaster
            icon="error"
            message={err?.response?.message ?? t("something_went_wrong")}
          />
        );
      });
  };
  const results = searchResults.filter((table) => {
    return table.name.toLowerCase().includes(searchTerm.toLowerCase());
  });
  return (
    <>
      <Row>
        <Col xs={12} lg={3} md={8} sm={7} className="mb-4">
          <Search
            placeholder={t("search")}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
        </Col>
        <Col
          xs={12}
          lg={9}
          md={4}
          sm={5}
          className="d-inline-flex justify-content-end mb-4"
        >
          <Button
            className="text-capitalize"
            variant="primary"
            onClick={() => setShow(true)}
          >
            {t("add_new_technician")}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table responsive borderless className="global-table">
            <thead className="border-bottom">
              <tr className="text-dark-gray">
                {tableStructure.map((col, i) => {
                  return (
                    <th className="font-weight-normal" key={i}>
                      {col.colName}
                    </th>
                  );
                })}
              </tr>
            </thead>
            {tableViewLoader ? (
              <TableViewLoader />
            ) : (
                results.length < 1 ? (
                  <tbody>
                    <tr>
                      <td className="py-2 text-center lead" colspan="5">
                        {t("data_not_available")}
                      </td>
                    </tr>
                  </tbody>
                ) : (
                    <tbody>
                      {results.map((data) => {
                        return (
                          <tr key={data.id}>
                            <td style={{ maxWidth: "180px" }} className="text-truncate">
                              {data.name}
                            </td>
                            <td style={{ maxWidth: "180px" }} className="text-truncate">
                              {data.email}
                            </td>
                            <td>{data.contact}</td>
                            <td>
                              {data.techDairies
                                ? (data.techDairies.length <= 1)
                                  ? data.techDairies.length + " " + t('site')
                                  : data.techDairies.length + " " + t('sites')
                                : ""}
                            </td>
                            <td style={{ minWidth: "215px" }}>
                              <div>
                                <span className="pr-2">
                                  {data["isActive"] === undefined
                                    ? "Yes"
                                    : data.isActive === false
                                      ? "No"
                                      : "Yes"}
                                </span>
                                <span>
                                  {data.isActive === false ? (
                                    <Button
                                      variant="outline-warning"
                                      className="text-capitalize"
                                      onClick={() => {
                                        setSelectedEmail(data.email);
                                        sendUserActivationLink(data.email);
                                      }}
                                    >
                                      <Image
                                        src={Email}
                                        className="pr-2"
                                        fluid
                                        style={{
                                          verticalAlign: "initial",
                                        }}
                                      />
                                      {t("resend_link")}
                                    </Button>
                                  ) : (
                                      ""
                                    )}
                                </span>
                              </div>
                            </td>
                            <td className="pt-1">
                              <Button
                                variant="link"
                                className="table-edit-button text-capitalize text-black"
                                onClick={() => {
                                  setUser(data.id);
                                  setDisplay(true);
                                  setTechData(data);
                                }}
                              >
                                <Image src={Edit} />
                              </Button>
                            </td>
                            <td className="pt-1">
                              <Button
                                variant="link"
                                className="delete-button text-danger text-capitalize"
                                onClick={() => {
                                  setSelectedId(data.id);
                                  setOpen(true);
                                }}
                              >
                                <Image src={Delete} />
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )
              )}
          </Table>
        </Col>
      </Row>


      {show && (
        <AddTechnicianForm
          showModel={show}
          closeModel={() => setShow(false)}
          updateList={updatedList}
        />
      )}
      {display && (
        <EditTechnicianForm
          showModel={display}
          closeModel={() => setDisplay(false)}
          userId={user}
          updateList={updatedList}
          techData={techData}
          sendUserActivationLink={(email) => sendUserActivationLink(email)}
        />
      )}

      <PromptModel
        open={open}
        closeAlert={() => {
          setOpen(false);
        }}
        modelContent={t("delete_record_msg")}
        callback={() => deleteTechnicians(selectId)}
      />
    </>
  );
});

export default TechniciansTab;
